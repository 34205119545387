import { useState, useEffect } from "react";
import "../css/ToolOptions.css";
import { useMainContext } from "../contexts/MainContext";
import { onlyIntInput} from "../utils/basics";

function GradientTool() {
  const { 
    ditherOffsetX,
    setDitherOffsetX,
    ditherOffsetY,
    setDitherOffsetY,
    gradientAlphaLock,
    setGradientAlphaLock
  } = useMainContext();

  const [displayOffsetX, setDisplayOffsetX] = useState(ditherOffsetX);
  const [displayOffsetY, setDisplayOffsetY] = useState(ditherOffsetY);

  const handleOffsetXChange = (e) => {
    if(e.target.value > 3){
      setDitherOffsetX(3);
      setDisplayOffsetX(3);
    } else if(e.target.value < 0){
      setDitherOffsetX(0);
      setDisplayOffsetX('');
    } else {
      setDitherOffsetX(parseInt(e.target.value));
      setDisplayOffsetX(e.target.value);
    }
  };

  const handleOffsetYChange = (e) => {
    if(e.target.value > 3){
      setDitherOffsetY(3);
      setDisplayOffsetY(3);
    } else if(e.target.value < 0){
      setDitherOffsetY(0);
      setDisplayOffsetY('');
    } else {
      setDitherOffsetY(parseInt(e.target.value));
      setDisplayOffsetY(e.target.value);
    }
  };

  const handleAlphaLock = (checked) => {
    setGradientAlphaLock(checked);
  };

  return (
    <>
      <h4>Gradient Tool</h4>
      <label className="tool-label">Offset X: <input
        className="tool-integer"
        type="number"
        value={ditherOffsetX}
        onChange={handleOffsetXChange}
        onKeyDown={onlyIntInput}
        min={0}
        max={3}
      ></input></label>
      <label className="tool-label">Offset Y: <input
        className="tool-integer"
        type="number"
        value={ditherOffsetY}
        onChange={handleOffsetYChange}
        onKeyDown={onlyIntInput}
        min={0}
        max={3}
      ></input></label><label className="tool-label">
      Alpha Lock:
      <input
        className="tool-checkbox"
        type="checkbox"
        onChange={(e) => handleAlphaLock(e.target.checked)}
        checked={gradientAlphaLock}
      ></input>
    </label>
    </>
  );
}

export default GradientTool;
