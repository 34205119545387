import { useEffect, useState, useRef } from "react";
import "../css/Rename.css";
import { useMainContext } from "../contexts/MainContext";

function Rename({ onSave }) {
    const { docName, setDocName} = useMainContext();

    const [name, setName] = useState(docName);
    const [errorMessage, setErrorMessage] = useState(null);

    const nameInputRef = useRef(null);

    useEffect(() => {
        setName(docName);
        if (nameInputRef.current) {
            nameInputRef.current.focus();
        }
    }, [docName]);

    const handleNameChange = (e) => {
        setName(e.target.value.substring(0, 64));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(!name) return setErrorMessage("Document name is required.");
        if(name.length > 64) return setErrorMessage("Document name must be 64 or less characters.");
        if(!/^[A-Za-z0-9 _-]+$/.test(name)) return setErrorMessage("Document name contains invalid characters.");
        
        setDocName(name.trim());

        onSave();
    };

    return (
        <div className="rename-wrapper">
            <h2 className="rename-heading">Rename Document</h2>
            <label className="rename-label" htmlFor="name">New Name:</label>
            <input ref={nameInputRef} id="name" className="rename-input-name" value={name} onChange={handleNameChange} autoComplete="off" maxlength="64"/>
            {errorMessage && <div className="rename-error">{errorMessage}</div>}
            <div className="rename-bottom"><input type="submit" value="Rename" onClick={(event) => {handleSubmit(event)}}/></div>
        </div>
    );
}

export default Rename;
