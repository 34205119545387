import { useState, useEffect, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { ChromePicker } from "react-color";
import "../css/Header.css";
import { useMainContext } from "../contexts/MainContext";
import { useUserContext } from "../contexts/UserContext";
import SignIn from "./SignIn";
import NewDocument from "./NewDocument";
import SavePalette from "./SavePalette";
import LoadPalette from "./LoadPalette";
import ChangeSwatch from "./ChangeSwatch";
import Rename from "./Rename";
import Export from "./Export";
import Open from "./Open";
import Import from "./Import";
import CropCanvas from "./CropCanvas";
import ScaleCanvas from "./ScaleCanvas";
import Hotkeys from './Hotkeys';
import { maxLayerCount, metaKey} from "../constants";
import logo from '../assets/images/logo.svg';

function Header() {
  const navigate = useNavigate();

  const [tempColor, setTempColor] = useState(null);
  const [dropDownItems, setDropDownItems] = useState(null);

  const {
    documentSaving,
    layers,
    colorModal,
    setColorModal,
    swatchModal,
    setSwatchModal,
    signInModal,
    setSignInModal,
    newDocumentModal,
    setNewDocumentModal,
    savePaletteModal,
    setSavePaletteModal,
    loadPaletteModal,
    setLoadPaletteModal,
    importModal,
    setImportModal,
    renameModal,
    setRenameModal,
    setExportModal,
    exportModal,
    openModal,
    setOpenModal,
    cropModal,
    setCropModal,
    scaleModal,
    setScaleModal,
    hotkeyModal,
    setHotkeyModal,
    setSaveNow,
    setSaveNewNow,
    signOut,
    palette,
    palettes,
    selectedSwatch,
    setSelectedSwatch,
    loadPalettes,
    setDuplicateNow,
    setMergeDownNow,
    setDeleteLayerNow,
    setMixColorsNow,
    setSwapColorsNow,
    setDeleteSwatchNow,
    setDeleteDocNow,
    setSelectAllNow,
    setDeslectNow,
    setInvertNow,
    setGrowNow,
    setShrinkNow,
    setDeleteSelectionNow,
    setCopyNow,
    setCutNow,
    setPasteNow,
    setPasteInPlaceNow,
    setTransformNow,
    importImage,
    starred,
    handleStar,
    handleAutoSave,
    autoSave,
    updatePalette,
    setUndoNow,
    setRedoNow,
    setFlipHorizontalNow,
    setFlipVerticalNow,
    setRotateCWNow,
    setRotateCCWNow,
    isDocumentLoaded,
    showBorder,
    setShowBorder,
    addLayer,
    loading,
    setLoading,
  } = useMainContext();

  const { user, setUser } = useUserContext();

  useEffect(() => {
    const signInItem = user ?
      { key: 'sign out', label: "Sign Out: " + user.displayName, callback: () => {signOut()}}
      :
      { key: 'sign in', label: "Sign In", callback: () => {setSignInModal(true)}};

    setDropDownItems({
      File: [
        { label: 'New', callback: () => {loadPalettes(true)} },
        { label: 'Open', callback: () => setOpenModal(true) },
        { label: 'Save', callback: () => {setSaveNow((prevValue) => prevValue + 1)}, hotkey: metaKey + "+S"},
        { label: 'Save As New', callback: () => {setSaveNewNow((prevValue) => prevValue + 1)} },
        { label: 'Auto Save', callback: () => {handleAutoSave()}, checked: autoSave },
        { divider: true },
        { label: 'Export', callback: () => setExportModal(true), hotkey: metaKey + "+Alt+S"},
        { label: 'Import', callback: () => openImportModal() },
        { divider: true },
        { label: 'Rename', callback: () => {setRenameModal(true)} },
        { label: 'Starred', callback: () => {handleStar()}, checked: starred},
        { label: 'Delete Document', callback: () => {setDeleteDocNow((prevValue) => prevValue + 1)} },
        { divider: true },
        signInItem
      ],
      Edit: [
        { label: 'Copy', callback: () => {setCopyNow((prevValue) => prevValue + 1)}, hotkey: metaKey + "+C" },
        { label: 'Cut', callback: () => { setCutNow((prevValue) => prevValue + 1)}, hotkey: metaKey + "+X" },
        { label: 'Paste', callback: () => {setPasteNow((prevValue) => prevValue + 1)}, hotkey: metaKey + "+V" },
        { label: 'Paste In Place', callback: () => {setPasteInPlaceNow((prevValue) => prevValue + 1)}, hotkey: metaKey + "+Shift+V" },
        { label: 'Transform', callback: () => {setTransformNow((prevValue) => prevValue + 1)}, hotkey: "T" },
        { divider: true },
        { label: 'Flip Horizontal', callback: () => {setFlipHorizontalNow((prevValue) => prevValue + 1)} },
        { label: 'Flip Vertical', callback: () => {setFlipVerticalNow((prevValue) => prevValue + 1)} },
        { label: 'Rotate CW', callback: () => {setRotateCWNow((prevValue) => prevValue + 1)} },
        { label: 'Rotate CCW', callback: () => {setRotateCCWNow((prevValue) => prevValue + 1)} },
        { label: 'Scale Canvas', callback: () => {setScaleModal(true)} },
        { label: 'Crop Canvas', callback: () => {setCropModal(true)} },
        { divider: true },
        { label: 'Undo', callback: () => {setUndoNow((prevValue) => prevValue + 1)}, hotkey: metaKey + "+Z" },
        { label: 'Redo', callback: () => {setRedoNow((prevValue) => prevValue + 1)}, hotkey: metaKey + "+Shift+Z"},
        { divider: true },
        { label: 'Mix Colors', callback: () => {setMixColorsNow((prevValue) => prevValue + 1)} },
        { label: 'Swap Colors', callback: () => {setSwapColorsNow((prevValue) => prevValue + 1)} },
        { label: 'Delete Color', callback: () => {setDeleteSwatchNow((prevValue) => prevValue + 1)} },
      ],
      Layer: [
        { label: 'Add Canvas', callback: () => {addLayer("Canvas")} },
        { label: 'Delete Layers', callback: () => {setDeleteLayerNow((prevValue) => prevValue + 1)} },
        { label: 'Duplicate Layer', callback: () => {setDuplicateNow((prevValue) => prevValue + 1)}, hotkey: metaKey + "+J"},
        { label: 'Merge Down', callback: () => {setMergeDownNow((prevValue) => prevValue + 1)} },
      ],
      Select: [
        { label: 'Select All', callback: () => {setSelectAllNow((prevValue) => prevValue + 1)}, hotkey: metaKey + "+A"},
        { label: 'Deselect', callback: () => {setDeslectNow((prevValue) => prevValue + 1)}, hotkey: metaKey + "+Shift+A"},
        { label: 'Invert', callback: () => {setInvertNow((prevValue) => prevValue + 1)}, hotkey: metaKey + "+I"},
        { divider: true },
        { label: 'Grow', callback: () => {setGrowNow((prevValue) => prevValue + 1)}, hotkey: metaKey + "+'"},
        { label: 'Shrink', callback: () => {setShrinkNow((prevValue) => prevValue + 1)}, hotkey: metaKey + "+;"},
        { divider: true },
        { label: 'Delete Selection', callback: () => {setDeleteSelectionNow((prevValue) => prevValue + 1)} },
      ],
      Guides: [
        { label: 'Add Line', callback: () => {addLayer('Line')} },
        { label: 'Add Perspective', callback: () => {addLayer('ThreePoint')} },
        { label: 'Add Vanishing Point', callback: () => {addLayer('OnePoint')} },
        { label: 'Add Grid', callback: () => {addLayer('Grid')} },
        { label: 'Add Isometric', callback: () => {addLayer('Isometric')} },
        { label: 'Add Ellipse', callback: () => {addLayer('Ellipse')} },
        { label: 'Show Border', callback: () => {setShowBorder((prevValue) => !prevValue)}, checked: showBorder},
      ],
      About: [
        { label: 'Guide', callback: () => {window.open("/guide", '_blank', 'noopener,noreferrer')}},
        { label: 'Tutorial', callback: () => {window.open("https://www.youtube.com/watch?v=9MNOm2DLqNQ", '_blank', 'noopener,noreferrer')}},
        { label: 'Hotkeys', callback: () => {setHotkeyModal(true)}},
        { label: 'Discord', callback: () => {window.open("https://discord.gg/ZUFWfzfR4G", '_blank', 'noopener,noreferrer')}},
        { label: 'Support', callback: () => {window.open("/support", '_blank', 'noopener,noreferrer')}},
      ]
    });
  }, [user, starred, autoSave, showBorder, layers, addLayer]);

  const [fullscreen, setFullscreen] = useState(false);

  // Function to handle the click event for entering/exiting full screen
  const handleFullScreen = () => {
    // Check if the page is currently in full-screen mode
    if (!fullscreen) {
      setFullscreen(true);
      // Attempt to enter full-screen mode
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        /* Firefox */
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        document.documentElement.webkitRequestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        /* IE/Edge */
        document.documentElement.msRequestFullscreen();
      }
    } else {
      setFullscreen(false);

      // Exit full-screen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen();
      }
    }
  };

  const onSuccessfulLogin = (userData) => {
    setUser(userData);
    closeModal();
  };


  
  const closeColorPicker = useCallback(() => {
    closeModal();
    setTempColor(palette[selectedSwatch]);
  }, [tempColor, selectedSwatch, palette]);


  const okayColorPicker = useCallback(() => {
    if (tempColor) {
      updatePalette(colorModal, tempColor);
    } else {
      updatePalette(colorModal, palette[selectedSwatch]);
    }
    setSelectedSwatch(colorModal);
    closeModal();
  }, [palette, selectedSwatch, colorModal, tempColor]);

  useEffect(() => {
    setTempColor(palette[selectedSwatch]);
  }, [palette, selectedSwatch]);

  const handleColorChange = (color) => {
    setTempColor(color.hex);
  };


  const closeImportModal = (canvas, name) => {
    importImage(canvas, name);
    closeModal();
  };
  const openImportModal = useCallback(() => {
    if(layers.length >= maxLayerCount) return Window.alert(`Max layer count reached. (${maxLayerCount})`);
    setImportModal(true)
  }, [layers]);

  const closeModal = () => {
    setColorModal(false);
    setSwatchModal(false);
    setNewDocumentModal(false);
    setSavePaletteModal(false);
    setRenameModal(false);
    setLoadPaletteModal(false);
    setExportModal(false);
    setOpenModal(false);
    setImportModal(false);
    setCropModal(false);
    setScaleModal(false);
    setHotkeyModal(false);
    setSignInModal(false);
  };

  const changeToSignIn = () => {
    setColorModal(false);
    setSwatchModal(false);
    setNewDocumentModal(false);
    setSavePaletteModal(false);
    setRenameModal(false);
    setLoadPaletteModal(false);
    setExportModal(false);
    setOpenModal(false);
    setImportModal(false);
    setCropModal(false);
    setScaleModal(false);
    setHotkeyModal(false);

    setSignInModal(true);
  };

  const fsIcon = fullscreen ? "icon-shrink2" : "icon-enlarge2";  

  return (
    <header className="header" style={{ cursor: loading ? 'wait' : 'default' }}>
      <a href="/dashboard"><img className="header-logo" src={logo} alt="Aliasing.Pro Frog Logo"></img></a>
      {['File', 'Edit', 'Layer', 'Select', 'Guides', 'About'].map(
        (variant) => (
          <DropdownButton
            as={ButtonGroup}
            key={variant}
            id={`dropdown-variants-${variant}`}
            className="header-item"
            variant={variant.toLowerCase()}
            title={variant}
            data-bs-theme="dark"
          >
            {dropDownItems && dropDownItems[variant].map((item, index) => {
              if(item.divider){
                return <Dropdown.Divider key={index} />
              } else {
                if(!item.hidden) return (<Dropdown.Item key={index} eventKey={item.label} onClick={item.callback} className="dropdown-item">
                  {item.checked ? <div className="droppdown-checked">✔</div> : <div className="droppdown-checked"></div>}
                  {item.label}
                  {item.hotkey && <><div className="grow droppdow-space"></div><span>{item.hotkey}</span></>}
                  </Dropdown.Item>)
              }
            })}
          </DropdownButton>
        ),
      )}
      <div className="grow"></div>
      {/* <button className="header-button" onClick={handleFullScreen}>
        <span className={fsIcon}></span>
      </button> */}
      
      <Modal show={documentSaving} animation={false} backdrop="static" centereds>
        <div className="loading-wrapper">
          <span className="icon-spinner spinner"></span>
        </div>
      </Modal>
      
      <Modal show={!isDocumentLoaded} animation={false} backdrop="static" centered>
        <div className="loading-wrapper">
          <span className="icon-spinner spinner"></span>
        </div>
      </Modal>

      <Modal show={signInModal} onHide={closeModal} animation={false} backdrop="static" centered>
        <SignIn onSuccessfulLogin={onSuccessfulLogin} />
        <button onClick={closeModal}>
          <span className="close-button icon-x"></span>
        </button>
      </Modal>

      <Modal show={newDocumentModal} onHide={closeModal} animation={false} backdrop="static" centered>
        <NewDocument palettes={palettes} newTab={true} onCreate={closeModal} />
        <button onClick={closeModal}>
          <span className="close-button icon-x"></span>
        </button>
      </Modal>

      <Modal show={savePaletteModal} onHide={closeModal} animation={false} backdrop="static" centered>
        <SavePalette onSave={closeModal} signIn={changeToSignIn}/>
        <button onClick={closeModal}>
          <span className="close-button icon-x"></span>
        </button>
      </Modal>

      <Modal show={loadPaletteModal} onHide={closeModal} animation={false} backdrop="static" centered>
        <LoadPalette onLoad={closeModal} signIn={changeToSignIn}/>
        <button onClick={closeModal}>
          <span className="close-button icon-x"></span>
        </button>
      </Modal>

      <Modal show={renameModal} onHide={closeModal} animation={false} backdrop="static" centered>
        <Rename onSave={closeModal}/>
        <button onClick={closeModal}>
          <span className="close-button icon-x"></span>
        </button>
      </Modal>

      <Modal show={exportModal} onHide={closeModal} animation={false} backdrop="static" centered>
        <Export onSave={closeModal}/>
        <button onClick={closeModal}>
          <span className="close-button icon-x"></span>
        </button>
      </Modal>

      <Modal show={openModal} onHide={closeModal} animation={false} backdrop="static" centered>
        <Open palettes={palettes} newTab={true} onCreate={closeModal} />
        <button onClick={closeModal}>
          <span className="close-button icon-x"></span>
        </button>
      </Modal>

      <Modal show={importModal} onHide={closeModal} animation={false} backdrop="static" centered>
        <Import palette={palette} onCreate={closeImportModal} />
        <button onClick={closeModal}>
          <span className="close-button icon-x"></span>
        </button>
      </Modal>

      <Modal show={colorModal || colorModal === 0} onHide={closeColorPicker} animation={false} backdrop="static" centered>
        <div className="color-wrapper">
          <ChromePicker
            color={tempColor}
            onChangeComplete={handleColorChange}
            disableAlpha={true}
          />
          <button className="color-okay" onClick={okayColorPicker}>Okay</button>
        </div>
        <button onClick={closeColorPicker}>
          <span className="close-button icon-x"></span>
        </button>
      </Modal>

      <Modal show={swatchModal} onHide={closeModal} animation={false} backdrop="static" centered>
        <ChangeSwatch onChange={closeModal} />
        <button onClick={closeModal}>
          <span className="close-button icon-x"></span>
        </button>
      </Modal>

      <Modal show={cropModal} onHide={closeModal} animation={false} backdrop="static" centered>
        <CropCanvas onCrop={closeModal} />
        <button onClick={closeModal}>
          <span className="close-button icon-x"></span>
        </button>
      </Modal>

      <Modal show={scaleModal} onHide={closeModal} animation={false} backdrop="static" centered>
        <ScaleCanvas onScale={closeModal} />
        <button onClick={closeModal}>
          <span className="close-button icon-x"></span>
        </button>
      </Modal>

      <Modal show={hotkeyModal} onHide={closeModal} animation={false} backdrop="static" centered>
        <Hotkeys />
        <button onClick={closeModal}>
          <span className="close-button icon-x"></span>
        </button>
      </Modal>
    </header>
  );
}

export default Header;
