import "../css/Support.css";
import logo from '../assets/images/logo.svg';

function Support() {
  return (
    <div className="support-wrapper">
      <nav className="support-nav">
        <a href="/"><img className="header-logo" src={logo} alt="Aliasing.Pro Frog Logo"></img></a>
        <a href="/">Home</a>
        <a href="/dashboard">Dashboard</a>
      </nav>
      <div className="support">
        <h1 className="support-heading">Aliasing.Pro Support</h1>
        <p>There is a guide to using the web app <a href="guide">here</a>.</p>
        <h2 className="support-heading">Still need support?</h2>
        <p style={{textAlign: "left"}}><a href="https://discord.gg/ZUFWfzfR4G" target="_blank" rel="noreferrer">Join our Discord</a> or send us an email with any questions or to report any bugs. If you are reporting a bug, include a link to the editor page where you are experiencing issues.</p>
        <p>Email: <a href="mailto:admin@aliasing.pro">admin@aliasing.pro</a></p>
      </div>
      <div className="grow"></div>
      <footer className="copywrite">© 2023-{new Date().getFullYear()} Aliasing.Pro</footer>
    </div>
  );
}

export default Support;
