const DocumentContextMenu = ({ position, rename, archive, deleteNow, onClose, documentId, unarchive, groupView}) => {
    return (
        <div 
        className="context-menu" 
        style={{ top: `${position.y}px`, left: `${position.x}px` }}
        >
            <button className="grow" onClick={() => rename(documentId)}>Rename</button>
            {!groupView && <button className="grow" onClick={() => archive(documentId)}>{unarchive ? "Unarchive" : "Archive"}</button>}
            <button className="grow" onClick={() => deleteNow(documentId)}>Delete</button>
            <button className="grow" onClick={onClose}>Close</button>
        </div>
    );
};
  

export default DocumentContextMenu;