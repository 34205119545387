import { useState, useEffect, useRef } from "react";
import { apiUrl} from "../constants";
import axios from 'axios';
import "../css/NewGroup.css";
import { useUserContext } from "../contexts/UserContext";
import { storeGroupData } from "../utils/document";

function NewGroup({ onCreate, num}) {
    const [name, setName] = useState('Group');
    const [errorMessage, setErrorMessage] = useState(null);

    const { user } = useUserContext();

    const nameInputRef = useRef(null);

    useEffect(() => {
        if(num > 0){
            setName(`Group ${num}`)
        }
        // Automatically focus on the name input when the component is rendered
        if (nameInputRef.current) {
            nameInputRef.current.focus();
        }
    }, []);

    const handleNameChange = (e) => {
        setName(e.target.value.substring(0, 32));
    };
    
    // Helper function to store document ID in local storage
    const handleSubmit = async (e) => {
        e.preventDefault();

        if(!name) return setErrorMessage("Group name is required.");
        if(name.length > 32) return setErrorMessage("Group name must be 32 or less characters.");
        if(!/^[A-Za-z0-9_ -]+$/.test(name)) return setErrorMessage("Group name contains invalid characters.");
        
        const payload = { name: name.trim() };

        try {
            const response = await axios.post(apiUrl + "/api/newGroup", payload);
    
            if (response.status === 201) {
                const { token, group } = response.data;
    
                // Store the document ID in local storage for all users
                storeGroupData(group.id, token);
                
                onCreate(group);
            }
        } catch (error) {   
            if (error.response && error.response.data && error.response.data.message) {
                return setErrorMessage(error.response.data.message);
            } else {
                console.error(error);
                return setErrorMessage("Error creating group.");
            }
        }
    };

    return (
        <div className="new-group-wrapper">
            <h2 className="new-group-heading">New Group</h2>
            <label className="new-group-label" htmlFor="name">Group Name:</label>
            <input
                ref={nameInputRef}
                id="name"
                className="new-group-input-name" 
                value={name} 
                onChange={handleNameChange} 
                autoComplete="off" 
                maxlength="64"
            />
            {errorMessage && <div className="new-group-error">{errorMessage}</div>}
            <div className="new-group-bottom"><input type="submit" value="Create" onClick={(event) => {handleSubmit(event)}}/></div>
        </div>
    );
}

export default NewGroup;
