import { useParams } from "react-router-dom";
import "../css/Editor.css";
import { MainProvider } from "../contexts/MainContext";
import Header from "./Header";
import Tools from "./Tools";
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";

function Editor() {
  let { id } = useParams();

  return (
    <MainProvider docId={id}>
      <Header />
      <div className="wrapper grow">
          <Tools />
          <div className="wrapper grow">
            <LeftPanel></LeftPanel>
            <RightPanel></RightPanel>
          </div>
      </div>
    </MainProvider>
  );
}

export default Editor;
