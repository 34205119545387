import { useEffect, useState } from "react";
import { apiUrl } from "../constants";
import { useParams } from "react-router-dom";
import axios from 'axios';
import "../css/VerifyEmail.css";

function VerifyEmail() {
    let { username } = useParams();
    let { token } = useParams();
    const [password, setPassword] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [emailResentMessage, setEmailResentMessage] = useState(null);
    const [resetPasswordMessage, setResetPasswordMessage] = useState(null);
    const [isValidToken, setIsValidToken] = useState(false);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const verifyToken = (e) => {
        e.preventDefault();

        if(!password){
            return setErrorMessage('Password is required.')
        }
        const payload = {
            username,
            token,
            password
        }
        axios.post(apiUrl + "/api/auth/verifyEmailToken", payload)
            .then(response => {
                if (response.status === 200) {
                    setIsValidToken(true);
                }
            })
            .catch(error => {
                if (error.response){
                    if(error.response.status === 500){
                        setErrorMessage((<>{error.response.data.message} <button className="resend-button" onClick={handleResend}>Resend Email</button></>));
                        setEmailResentMessage(null);
                        setResetPasswordMessage(null);
                    } else if (error.response.data && error.response.data.message) {
                        if(error.response.data.reset){
                            setErrorMessage((<>{error.response.data.message} <button className="reset-password" onClick={handleResetPassword}>Reset Password</button></>));
                            setEmailResentMessage(null);
                            setResetPasswordMessage(null);
                        } else {
                            setErrorMessage(error.response.data.message);
                            setEmailResentMessage(null);
                            setResetPasswordMessage(null);
                        }
                    }
                } 
            });
    };

    const handleResend = () => {
        const payload = {
            username: username
        }
        axios.post(apiUrl + "/api/auth/resendEmail", payload)
            .then(response => {
                if (response.status === 200) {
                    setEmailResentMessage('A verification email was resent');
                    setErrorMessage(null);
                    setResetPasswordMessage(null);
                }
            })
            .catch(error => {
                // Check if the error response contains a custom message from the server
                if (error.response && error.response.data && error.response.data.message) {
                    setErrorMessage(error.response.data.message);
                    setEmailResentMessage(null);
                    setResetPasswordMessage(null);
                } 
            });
    };

    const handleResetPassword = () => {
        const payload = {
            username: username
        }
        axios.post(apiUrl + "/api/auth/resetPasswordEmail", payload)
            .then(response => {
                if (response.status === 200) {
                    setResetPasswordMessage('A password reset email was sent.');
                    setErrorMessage(null);
                }
            })
            .catch(error => {
                // Check if the error response contains a custom message from the server
                if (error.response && error.response.data && error.response.data.message) {
                    setResetPasswordMessage(null);
                    setErrorMessage(error.response.data.message);
                } 
            });
    };

    return (
        <div className="verify-center">
            {isValidToken ? 
                <div>
                    Your email has been verified and you can now sign in. <a href="/">Home</a>
                </div>
                :
                <>{emailResentMessage ?
                    <p>{emailResentMessage}</p>
                    :
                    <div className="password-form">
                        <label htmlFor="password" className="input-label">Verify your Aliasing.Pro account</label>
                        <input id="password" type="password" value={password} placeholder="Password" onChange={handlePasswordChange} autoFocus />
                        <div className="input-right">
                            <input type="submit" value="Verify" onClick={(event) => verifyToken(event)}></input>
                        </div>
                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                        {resetPasswordMessage && <p>{resetPasswordMessage}</p>}
                    </div>
                }</>
                
            }
        </div>
    );
}

export default VerifyEmail;
