import "../css/Error.css";

function InternalError() {
    return (
      <div  className="error-wrapper">
        <h1>500 - Internal Server Error</h1>
        <p>There was an error loading this resource.</p>
      </div>
    );
  }
  
  export default InternalError;