import React from 'react';
import '../css/CookieConsentFooter.css';

const CookieConsentFooter = ({ onAccept }) => {
    return (
        <div className="cookie-consent-footer">
            <p>We use cookies to improve your experience on our site. By using our site, you agree to our use of cookies.</p>
            <button onClick={onAccept}>Accept</button>
        </div>
    );
};

export default CookieConsentFooter;