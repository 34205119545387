import "../css/Hotkeys.css";
import { metaKey} from "../constants";

// Define a list of hotkeys
const hotkeys = [
    { key: `${metaKey}+S`, description: "Save the document" },
    { key: `${metaKey}+Alt+S`, description: "Export the document" },
    { key: `${metaKey}+Z`, description: "Undo" },
    { key: `${metaKey}+Shif+Z`, description: "Redo" },
    { key: `${metaKey}+C`, description: "Copy the selection" },
    { key: `${metaKey}+V`, description: "Paste" },
    { key: `${metaKey}+Shift+V`, description: "Paste in place" },
    { key: `${metaKey}+J`, description: "Duplicate layer" },
    { key: "N", description: "Add a canvas layer" },
    { key:  `${metaKey}+E`, description: "Merge down layers" },
    { key: "Delete", description: "Deleye layer or selection" },
    { key: "Space", description: "Drag viewport" },
    { key: "Z", description: "Zoom in/out on the canvas" },
    { key: "D", description: "Select first two colors" },
    { key: "X", description: "Swap selected colors" },
    { key: "H", description: "Select the hand tool" },
    { key: "M", description: "Select the move tool" },
    { key: "P", description: "Select the pencil tool" },
    { key: "E", description: "Select the eraser tool" },
    { key: "L", description: "Select the line tool" },
    { key: "B", description: "Select the bucket tool" },
    { key: "T", description: "Activate the transform tool" },
    { key: "I", description: "Select the eyedropper tool" },
    { key: "R", description: "Select the rectangle tool" },
    { key: "Shift+E", description: "Select the ellipse tool" },
    { key: "W", description: "Select the wand tool" },
    { key: "C", description: "Select the crop tool" },
    { key: "T", description: "Start a transformation" },
    { key: "Esc", description: "Reset transform or crop" },
    { key: "Enter", description: "Apply transform or crop" },
    { key: 'Tab', description: 'Next Layer'},
    { key: 'Shift+Tab', description: 'Previous Layer'},
    { key: "[", description: "Decrease tool diameter" },
    { key: "]", description: "Increase tool diameter" },
    { key: ";", description: "Select next swatch" },
    { key: "'", description: "Select previous swatch" },
    { key: `${metaKey}+A`, description: "Select all" },
    { key: `${metaKey}+D`, description: "Deselect any selection" },
    { key: `${metaKey}+I`, description: "Invert the current selection" },
    { key: `${metaKey}+'`, description: "Grow the selection" },
    { key: `${metaKey}+;`, description: "Shrink the selection" },
];

function Hotkeys() {
    return (
        <div className="hotkeys">
            <h2>HOTKEYS</h2>
            <table className="hotkeys-table">
                <thead>
                    <tr>
                        <th>Key</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                {hotkeys.map((hotkey, index) => (
                    <tr key={index}>
                    <td>{hotkey.key}</td>
                    <td>{hotkey.description}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

export default Hotkeys;
  