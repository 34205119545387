import { useEffect, useState } from 'react';
import DOMPurify from "dompurify";
import '../css/Privacy.css';
import logo from '../assets/images/logo.svg';

function Privacy() {
  const [termsContent, setTermsContent] = useState('');

  useEffect(() => {
    // Fetch the HTML content
    fetch('/PrivacyPolicy.html')
      .then(response => response.text())
      .then(data => {
            const sanitizedHTML = DOMPurify.sanitize(data);
            setTermsContent(sanitizedHTML);
        });
  }, []);

  return (
    <>
      <nav className="support-nav">
        <a href="/"><img className="header-logo" src={logo} alt="Aliasing.Pro Frog Logo"></img></a>
        <a href="/">Home</a>
        <a href="/dashboard">Dashboard</a>
      </nav>
      <div className='privacy-wrapper' dangerouslySetInnerHTML={{ __html: termsContent }} />
    </>
  );
}

export default Privacy;
