import "../css/ToolOptions.css";
import { useMainContext } from "../contexts/MainContext";

function ZoomTool() {
  const { invertZoom, setInvertZoom } = useMainContext();

  const handleInvertZoom = (checked) => {
    setInvertZoom(checked);
  };

  return (
    <>
      <h4>Zoom Tool</h4>
      <label className="tool-label">
        Invert Zoom:
        <input
          className="tool-checkbox"
          type="checkbox"
          onChange={(e) => handleInvertZoom(e.target.checked)}
          checked={invertZoom}
        ></input>
      </label>
    </>
  );
}

export default ZoomTool;
