export const generateId = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}

export const canvasToBlob = (canvas) => {
    return new Promise((resolve, reject) => {
        canvas.toBlob(blob => {
            if (blob) {
                resolve(blob);
            } else {
                reject(new Error('Canvas to Blob conversion failed'));
            }
        }, 'image/png');
    });
};

export const loadLayer = (id, layer) => {
    return new Promise((resolve, reject) => {
        try{
            const img = new Image();
            img.crossOrigin = "anonymous";
        
            img.onload = () => {
                const ctx = layer.ref.current.getContext("2d");
                ctx.drawImage(img, 0, 0);
                resolve();
            };
    
            img.onerror = (error) => {
                reject(new Error(`Failed to load image: ${error.message}`));
            };
    
            img.src = `https://aliasing.nyc3.digitaloceanspaces.com/${id}/${layer.id}.png?nocache=${new Date().getTime()}`;
        } catch(error){
            reject(error);
        }
    });
};

export const storeDocumentData = (docId, token) => {
    let documentData = JSON.parse(localStorage.getItem('documentData')) || {};
    documentData[docId] = token;
    localStorage.setItem('documentData', JSON.stringify(documentData));
    return token;
};

export const storeGroupData = (groupId, token) => {
    let groupData = JSON.parse(localStorage.getItem('groupData')) || {};
    groupData[groupId] = token;
    localStorage.setItem('groupData', JSON.stringify(groupData));
    return token;
};

export const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const relativeTime = (time) => {
    const current = Date.now();

    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;
    const msPerMonth = msPerDay * 30;
    const msPerYear = msPerDay * 365;

    let elapsed = current - time;

    if(elapsed < 0) elapsed = 0;

    if (elapsed < msPerMinute) {
         return Math.round(elapsed/1000) + ' sec ago';   
    }

    else if (elapsed < msPerHour) {
         return Math.round(elapsed/msPerMinute) + 'min ago';   
    }

    else if (elapsed < msPerDay ) {
        const hours = Math.round(elapsed/msPerHour);
        if(hours > 1){
            return hours + ' hours ago';  
        } else {
            return hours + ' hour ago';  
        }   
    }

    else if (elapsed < msPerMonth) {
        const days = Math.round(elapsed/msPerDay);
        if(days > 1){
            return days + ' days ago';  
        } else {
            return days + ' day ago';  
        } 
    }

    else if (elapsed < msPerYear) {
        const months = Math.round(elapsed/msPerMonth);
        if(months > 1){
            return months + ' months ago';  
        } else {
            return months + ' month ago';  
        }     
    }

    else {
        const years = Math.round(elapsed/msPerYear);
        if(years > 1){
            return years + ' years ago';  
        } else {
            return years + ' year ago';  
        } 
    }
}