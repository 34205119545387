import React from "react";
import "../css/Panel.css";
import { useMainContext } from "../contexts/MainContext";
import TopLeftWindow from "./TopLeftWindow";
import BottomLeftWindow from "./BottomLeftWindow";

function LeftPanel() {
  const {
    loading,
    setLoading,
  } = useMainContext();

  return (
    <div className="left-panel" style={{ cursor: loading ? 'wait' : 'default' }}>
      <TopLeftWindow />
      <BottomLeftWindow />
    </div>
  );
}

export default LeftPanel;
