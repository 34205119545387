import { useEffect, useState, useRef } from "react";
import { apiUrl } from "../constants";
import axios from 'axios';
import "../css/SavePalette.css";
import { useMainContext } from "../contexts/MainContext";
import { useUserContext } from "../contexts/UserContext";

function SavePalette({ onSave, signIn}) {
    const { palette, docName, palettesCreated, setPalettesCreated} = useMainContext();
    const { user } = useUserContext();

    const [name, setName] = palettesCreated > 0 ? useState(docName + ' Palette ' + (palettesCreated + 1)) : useState(docName + ' Palette');
    const [errorMessage, setErrorMessage] = useState(null);
    const [newPalette, setNewPalette] = useState(null);

    const nameInputRef = useRef(null);

    useEffect(() => {
        if(palettesCreated > 0){
            setName(docName.substring(0, 23 - palettesCreated.toString().length) + ' Palette ' + (palettesCreated + 1));
        } else {
            setName(docName.substring(0, 24) + ' Palette');
        }
        // Automatically focus on the name input when the component is rendered
        if (nameInputRef.current) {
            nameInputRef.current.focus();
        }

        //remove blank swatches
        const tempPalette = [];
        for(const swatch of palette){
            if(swatch) tempPalette.push(swatch);
        }
        setNewPalette([...tempPalette]);
    }, [docName, palette]);

    const handleNameChange = (e) => {
        setName(e.target.value);
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        if(name.length < 4) return setErrorMessage("Palette name must be 4 or more characters.");
        if(name.length > 32) return setErrorMessage("Palette name must be 32 or less characters.");
        if(!/^[A-Za-z0-9_ -]+$/.test(name)) return setErrorMessage("Palette name must only include letters, numbers, spaces, hyphens, and underscores.");
        
        const payload = {
            name: name.trim(),
            palette: newPalette
        };

        try {
            const response = await axios.post(apiUrl + "/api/savePalette", payload);
    
            if (response.status === 201) {    
                setPalettesCreated((prevValue) => prevValue + 1);
                onSave();
            }
        } catch (error) {   
            if (error.response && error.response.data && error.response.data.message) {
                return setErrorMessage(error.response.data.message);
            } else {
                console.error(error);
                return setErrorMessage("Error saving palette.");
            }
        }
    };

    return (
        <div className="save-palette-wrapper">
            <h2 className="save-palette-heading">Upload Palette</h2>
            {user ?
                <> 
                    <div className="save-palette-swatches">
                        {newPalette && newPalette.map((swatch, index) => (
                            <div className="save-palette-swatch" style={{ backgroundColor: newPalette[index] }}></div>
                        ))}
                    </div>
                    <label className="save-palette-label" htmlFor="name">Palette Name:</label>
                    <input ref={nameInputRef} id="name" className="save-palette-input-name" value={name} onChange={handleNameChange} maxLength="32"/>
                    {errorMessage && <div className="save-palette-error">{errorMessage}</div>}
                    <div className="save-palette-bottom"><input type="submit" value="Save" onClick={(event) => {handleSubmit(event)}}/></div>
                </>
                :
                <p>You must be signed in to save a palette. <button className="sign-in-button" onClick={signIn}>Sign in here.</button></p>
            }
        </div>
    );
}

export default SavePalette;
