import { useEffect, useState, useRef, useCallback } from "react";
import { apiUrl, defaultPalettes } from "../constants";
import axios from 'axios';
import RgbQuant from 'rgbquant';
import chroma from "chroma-js";
import "../css/LoadPalette.css";
import { useMainContext } from "../contexts/MainContext";
import { rampPalette } from "../utils/drawing";

function LoadPalette({ onLoad, signIn}) {
    const input = useRef(null);

    const [isLoadig, setIsLoading] = useState(false);
    const [imagePalette, setImagePalette] = useState(null);
    const [currentPalette, setCurrentPalette] = useState(0);    
    const [errorMessage, setErrorMessage] = useState(null);
    const [paletteArray, setPaletteArray] = useState(null);
    const [oldPalette, setOldPalette] = useState(null);

    const { newPalette, palettes, palette } = useMainContext();

    useEffect(() => {
        //remove blank swatches
        const tempPalette = [];
        let i = 0;
        const tempPaletteArray = [];
        for(const swatch of palette){
            if(swatch){
                tempPalette.push(swatch);
                tempPaletteArray.push(i);
                i++;
            }
        }
        for(let j = 32; j < 64; j++){
            if(tempPaletteArray.length < 32) tempPaletteArray.push(j);
        }
        setOldPalette([...tempPalette]);
        setPaletteArray([...tempPaletteArray]);
    }, [ palette]);

    const handleSwatchClick = (index) => {
        setErrorMessage(null);
        let newArray = [...paletteArray];
        if (newArray.includes(index)) {
            newArray = newArray.filter(i => i !== index);
            if(newArray.length === 0) return;
        } else {
            if (newArray.length < 32) {
                newArray.push(parseInt(index));
            }
        }
        setPaletteArray(newArray);
    };

    const selectAllOld = useCallback(() => {
        if(imagePalette && imagePalette.length){
            const tempPalette = [];
            for(let i = 0; i < oldPalette.length; i++){
                if(tempPalette.length < 32) tempPalette.push(i);    
            }
            for(let j = 32; j < 32 + imagePalette.length; j ++){
                if(paletteArray.includes(j) && tempPalette.length < 32) tempPalette.push(j);      
            }
            setPaletteArray([...tempPalette]);
        } else {
            const tempPalette = [];
            for(let i = 0; i < oldPalette.length; i++){
                if(tempPalette.length < 32) tempPalette.push(i);    
            }
            for(let j = 32; j < 32 + palettes[currentPalette].palette.length; j ++){
                if(paletteArray.includes(j) && tempPalette.length < 32) tempPalette.push(j);      
            }
            setPaletteArray([...tempPalette]);
        }
    }, [palettes, paletteArray, currentPalette]);;

    const selectAllNew = useCallback(() => {
        if(imagePalette && imagePalette.length){
            const tempPalette = [];
            for(let j = 32; j < 32 + imagePalette.length; j ++){
                if(tempPalette.length < 32) tempPalette.push(j);      
            }
            for(let i = 0; i < oldPalette.length; i++){
                if(paletteArray.includes(i) && tempPalette.length < 32) tempPalette.push(i);    
            }
            setPaletteArray([...tempPalette]);
        } else {
            const tempPalette = [];
            for(let j = 32; j < 32 + palettes[currentPalette].palette.length; j ++){
                if(tempPalette.length < 32) tempPalette.push(j);      
            }
            for(let i = 0; i < oldPalette.length; i++){
                if(paletteArray.includes(i) && tempPalette.length < 32) tempPalette.push(i);    
            }
            setPaletteArray([...tempPalette]);
        }
    }, [palettes, paletteArray, currentPalette]);

    const deselectAllOld = useCallback(() => {
        let newArray = [...paletteArray];
        newArray = newArray.filter(i => i > 31);
        if(newArray.length === 0) newArray = [32];
        setPaletteArray(newArray);
    }, [paletteArray, currentPalette]);

    const deselectAllNew = useCallback(() => {
        let newArray = [...paletteArray];
        newArray = newArray.filter(i => i < 32);
        if(newArray.length === 0) newArray = [0];
        setPaletteArray(newArray);
    }, [paletteArray, currentPalette]);

    const fillSelection = useCallback(() => {
        const newArray = [];
        for(let i = 0; i < 32; i++){
            if(paletteArray.includes(i)) newArray.push(i);    
        }
        if(currentPalette === -1){
            if(imagePalette && imagePalette.length){
                for(let j = 32; j < 32 + imagePalette.length; j ++){
                    if(newArray.length < 32) newArray.push(j);      
                }
            }
        } else {
            for(let j = 32; j < 32 + palettes[currentPalette].palette.length; j ++){
                if(newArray.length < 32) newArray.push(j);      
            }
        }
        setPaletteArray(newArray);
    }, [palettes, paletteArray, currentPalette]);

    const handlePaletteChange = (e) => {
        if(e.target.value === "file"){
            setCurrentPalette(-1);
        } else {
            setCurrentPalette(e.target.value);
        }
        setImagePalette(null);
        fillSelection();
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();

        if(paletteArray.length > 32){
            setErrorMessage('Palette must contain 32 or less swatches.');
            return;
        }

        const p = [];

        for(const index in oldPalette){
            if(paletteArray.includes(parseInt(index))){
                p.push(oldPalette[parseInt(index)]);
            }
        }
        if(imagePalette){
            for(const index in imagePalette){
                if(paletteArray.includes(32 + parseInt(index))){
                    p.push(imagePalette[parseInt(index)]);
                }
            }
        } else{
            for(const index in palettes[currentPalette].palette){
                if(paletteArray.includes(32 + parseInt(index))){
                    p.push(palettes[currentPalette].palette[parseInt(index)]);
                }
            }
        }
        
        newPalette(p);

        onLoad();
    };

    const handleDeletePalette = async (e) => {
        e.preventDefault();

        if(palettes[currentPalette].default || !palettes[currentPalette]._id){
            return setErrorMessage("You can't delete default palettes.")
        }
        
        await axios.delete(apiUrl + "/api/deletePalette/" + palettes[currentPalette]._id)
            .then(response => {
                if (response.status === 200) {
                    palettes.splice(currentPalette,1);
                    setCurrentPalette(currentPalette-1);
                }
            })
            .catch(error => {
                // Check if the error response contains a custom message from the server
                if (error.response && error.response.data && error.response.data.message) {
                    setErrorMessage(error.response.data.message);
                } else {
                    setErrorMessage('Error deleting palette.');
                }
            });
    };

    const swatchIncluded = (index) => {
        if(!paletteArray || !paletteArray.length) return "load-palette-swatch";
        const included = paletteArray.includes(index) ? "load-palette-swatch included" : "load-palette-swatch"
        return included;
    };

    const handleBrowse = (e) => {
        e.preventDefault();
        input.current.click();
    };

    function handleUpload(e) {
        const file = e.target.files[0];
        if (!file) {
            return setErrorMessage("No file uploaded.");;
        }

        setImagePalette(null);
        setIsLoading(true);
        setErrorMessage(null);

        const filename = file.name.toLowerCase();
        const extension = filename.split('.').pop();
        if(extension === "jpg" || extension === "jpeg" || extension === "png" || extension === "gif" || extension === "webp"){
            //upload file
            var img = new Image();
            img.onload = reduce;
            img.onerror = failed;
            img.src = URL.createObjectURL(file);
        } else if(extension === "txt"){
            const reader = new FileReader();
            reader.onload = function(e) {
                const content = e.target.result;
                const lines = content.split('\n');
                const newPalette = [];
            
                lines.forEach(line => {
                    line = line.trim();
                    if (line && !line.startsWith(';')) {
                        // Remove the leading FF if present
                        if (line.startsWith('FF')) {
                            line = line.slice(2, 8);
                        } else if(line.startsWith('#')){
                            line = line.slice(1, 7);
                        }
                        if(line !== '' && newPalette.length < 32) newPalette.push('#' + line);
                    }
                });
            
                setImagePalette(newPalette);
                setIsLoading(false);
                setTimeout(() => fillSelection(), 200);
            };
            reader.readAsText(file);
        } else if (extension === "hex"){
            const reader = new FileReader();
            reader.onload = function(e) {
                const content = e.target.result;
                const lines = content.split('\n');
                const newPalette = [];
            
                lines.forEach(line => {
                    line = line.trim();
                    line = line.slice(0, 6);
                    if(line !== '' && newPalette.length < 32) newPalette.push('#' + line);
                });
            
                setImagePalette(newPalette);
                setIsLoading(false);
                setTimeout(() => fillSelection(), 200);
            };
            reader.readAsText(file);
        } else {
            setIsLoading(false);
            return setErrorMessage("File must be a JPG, PNG, GIF, WEBP, TXT, or HEX.");
        }
    };

    function reduce(){
        const canvas = document.createElement("canvas");
        canvas.width = this.width;
        canvas.height = this.height;
        const ctx = canvas.getContext("2d");
        ctx.imageSmoothingEnabled = false;
        ctx.drawImage(this, 0, 0, canvas.width, canvas.height);

        //get sampled palette
        const q = new RgbQuant({colors: 32});
        q.sample(canvas);
        const pal = q.palette(true, true);
        
        const newPalette = []

        for(const color of pal){
            newPalette.push(chroma(color).hex());
        }

        const rampedPalette = rampPalette(newPalette);

        setImagePalette(rampedPalette);

        setIsLoading(false);
        
        fillSelection();
    }

    function failed(){
        return setErrorMessage("Error loading image.");
    }

    return (
        <div className="load-palette-wrapper">
            <h2 className="load-palette-heading">Replace Palette</h2>
            {palettes && <>
                <label className="load-palette-label">Current Palette:</label>
                <div className="load-palette-swatches">
                    {oldPalette && oldPalette.map((swatch, index) => (
                        <div
                            className={swatchIncluded(index)}
                            style={{ backgroundColor: swatch }}
                            onClick={() => handleSwatchClick(index)}
                        ></div>
                    ))}
                </div>  
                <div className="load-palette-right">
                    <button className="load-palette-button" onClick={deselectAllOld}>Deselect All</button>
                    <button className="load-palette-button" onClick={selectAllOld}>Select All</button>
                </div>
                <select
                    name="palettes"
                    className="load-palette-select"
                    onChange={(event) => {handlePaletteChange(event)}}
                    value={currentPalette}
                >
                    <option value="file">Load From File</option>
                    {palettes && palettes.map((paletteItem, index) => (
                        <option value={index}>{paletteItem.name}</option>
                    ))}
                </select>
                <div className="load-palette-swatches">
                    {isLoadig && 
                        <div className="palette-loading-wrapper">
                            <div>Loading colors.</div>
                            <span className="icon-spinner spinner"></span>
                        </div>
                    }
                    {palettes[currentPalette] && palettes[currentPalette].palette.map((swatch, index) => (
                        <div
                            className={swatchIncluded(32+index)}
                            style={{ backgroundColor: swatch }}
                            onClick={() => handleSwatchClick(32+index)}
                        ></div>
                    ))}
                    {currentPalette === -1 && imagePalette && imagePalette.map((swatch, index) => (
                        <div
                            className={swatchIncluded(32+index)}
                            style={{ backgroundColor: swatch }}
                            onClick={() => handleSwatchClick(32+index)}
                        ></div>
                    ))}
                </div>
                <div className="load-palette-right">
                    {(imagePalette || currentPalette !== -1) &&
                        <>
                            <button className="load-palette-button" onClick={deselectAllNew}>Deselect All</button>
                            <button className="load-palette-button" onClick={selectAllNew}>Select All</button>
                        </>
                    }
                    {currentPalette === -1 &&
                        <>
                            <input type="file" onChange={handleUpload} ref={input} style={{display: 'none'}} accept=".jpg,.jpeg,.png,.gif,.webp,.txt,.hex" />
                            <button className="load-palette-button" onClick={handleBrowse}>Browse</button>
                        </>
                    }
                </div>
                {errorMessage && <div className="load-palette-error">{errorMessage}</div>}
                {paletteArray && (
                    <>
                        <label className="load-palette-label">Swatches Selected: {paletteArray.length}/32</label>
                        <div className="load-palette-right">
                            {palettes[currentPalette] && !palettes[currentPalette].default && <input className="load-palette-button" type="submit" value="Delete Palette" onClick={(event) => {handleDeletePalette(event)}}/>}
                            {(imagePalette || currentPalette !== -1) && <input className="load-palette-button" type="submit" value="Load" onClick={(event) => {handleSubmit(event)}}/>}
                        </div>
                    </>)
                }
            </>}
        </div>
    );
}

export default LoadPalette;
