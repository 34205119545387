import React, {
    createContext,
    useState,
    useContext,
    useEffect,
    useRef
  } from "react";
  
  const UserContext = createContext();
  
  export const useUserContext = () => {
    return useContext(UserContext);
  };
  
  export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    
    return (
      <UserContext.Provider
        value={{
          user,
          setUser
        }}
      >
        {children}
      </UserContext.Provider>
    );
  };
  