import "../css/ToolOptions.css";
import { useMainContext } from "../contexts/MainContext";
import PencilTool from "./PencilTool";
import EraseTool from "./EraseTool";
import MoveTool from "./MoveTool";
import EyedropperTool from "./EyedropperTool";
import ZoomTool from "./ZoomTool";
import WandTool from "./WandTool";
import BucketTool from "./BucketTool";
import CropTool from "./CropTool";
import TransformTool from "./TransformTool";
import RectangleTool from "./RectangleTool";
import EllipseTool from "./EllipseTool";
import GradientTool from "./GradientTool";
import GuideTool from "./GuideTool";
import LineTool from "./LineTool";

function ToolOptions() {
  const { selectedTool } = useMainContext();

  const renderToolContent = () => {
    switch (selectedTool) {
      case "pencil":
        return <PencilTool />;
      case "erase":
        return <EraseTool />;
      case "move":
        return <MoveTool />;
      case "eyedropper":
        return <EyedropperTool />;
      case "zoom":
        return <ZoomTool />;
      case "wand":
        return <WandTool />;
      case "bucket":
        return <BucketTool />;
      case "rectangle":
        return <RectangleTool />;
      case "ellipse":
        return <EllipseTool />;
      case "transform":
        return <TransformTool />;
      case "crop":
        return <CropTool />;
      case "hand":
        return <h4>Hand Tool</h4>;
      case "gradient":
        return <GradientTool />;
      case "guide":
        return <GuideTool />;
      case "line":
        return <LineTool />;
    }
  };

  return (
    <div className="tool-wrapper-outer">
      <div className="tool-wrapper-inner">{renderToolContent()}</div>
    </div>
  );
}

export default ToolOptions;
