import "../css/ToolOptions.css";
import { useMainContext } from "../contexts/MainContext";

function EyedropperTool() {
  const {
    dropperCurrent,
    setDropperCurrent,
    dropperReplace,
    setDropperReplace
  } = useMainContext();

  const handleAutoSelect = (checked) => {
    setDropperCurrent(checked);
  };

  const handleReplace = (checked) => {
    setDropperReplace(checked);
  };

  return (
    <>
      <h4>Eyedropper</h4>
      <label className="tool-label">
        Current Layer:
        <input
          className="tool-checkbox"
          type="checkbox"
          onChange={(e) => handleAutoSelect(e.target.checked)}
          checked={dropperCurrent}
        ></input>
      </label>
      <label className="tool-label">
        Replace Color:
        <input
          className="tool-checkbox"
          type="checkbox"
          onChange={(e) => handleReplace(e.target.checked)}
          checked={dropperReplace}
        ></input>
      </label>
    </>
  );
}

export default EyedropperTool;
