import { useState, useEffect } from "react";
import "../css/ToolOptions.css";
import { useMainContext } from "../contexts/MainContext";
import { maxCursorSize, metaKey} from "../constants";
import { onlyIntInput} from "../utils/basics";

function PencilTool() {
  const {
    brushDiameter,
    setBrushDiameter,
    brushPixelPerfect,
    setBrushPixelPerfect,
    brushMode,
    setBrushMode,
    brushPressure,
    setBrushPressure,
    brushSelect,
    setBrushSelect,
    brushPressureFactor,
    setBrushPressureFactor,
    ditherRatio,
    setDitherRatio,
    ditherOffsetX,
    setDitherOffsetX,
    ditherOffsetY,
    setDitherOffsetY,
    ditherPressureMode,
    setDitherPressureMode,
  } = useMainContext();

  const [displaySize, setDisplaySize] = useState(brushDiameter);
  const [displayFactor, setDisplayFactor] = useState(9-brushPressureFactor);
  const [displayOffsetX, setDisplayOffsetX] = useState(ditherOffsetX);
  const [displayOffsetY, setDisplayOffsetY] = useState(ditherOffsetY);

  const handleRageChange = (e) => {
    setBrushDiameter(parseInt(e.target.value));
  };

  useEffect(() =>{
    setDisplaySize(brushDiameter);
  }, [brushDiameter]);

  const handleSizeChange = (e) => {
    if(e.target.value > maxCursorSize){
      setBrushDiameter(maxCursorSize);
      setDisplaySize(maxCursorSize);
    } else if(e.target.value <= 0){
      setBrushDiameter(1);
      setDisplaySize('');
    } else {
      setBrushDiameter(parseInt(e.target.value));
      setDisplaySize(e.target.value);
    }
  };

  const handleOffsetXChange = (e) => {
    if(e.target.value > 3){
      setDitherOffsetX(3);
      setDisplayOffsetX(3);
    } else if(e.target.value < 0){
      setDitherOffsetX(0);
      setDisplayOffsetX('');
    } else {
      setDitherOffsetX(parseInt(e.target.value));
      setDisplayOffsetX(e.target.value);
    }
  };

  const handleOffsetYChange = (e) => {
    if(e.target.value > 3){
      setDitherOffsetY(3);
      setDisplayOffsetY(3);
    } else if(e.target.value < 0){
      setDitherOffsetY(0);
      setDisplayOffsetY('');
    } else {
      setDitherOffsetY(parseInt(e.target.value));
      setDisplayOffsetY(e.target.value);
    }
  };

  const handleBrushPressure = (checked) => {
    setBrushPressure(checked);
  };

  const handlePixelPerfect = (checked) => {
    setBrushPixelPerfect(checked);
  };

  const handlePressureFactor = (e) => {
    setBrushPressureFactor(9 - e.target.value);
    setDisplayFactor(e.target.value);
  };

  const handleBrushSelect = (checked) => {
    setBrushSelect(checked);
  };

  const handleModeChange = (e) => {
      setBrushMode(e.target.value);
  };

  const handleDitherRatio = (e) => {
    setDitherRatio(e.target.value);
  };

  const handlePressureModeChange = (e) => {
    setDitherPressureMode(e.target.value);
  };

  return (
    <>
      <h4>Pencil Tool</h4>
      <label className="tool-label">Size: <input
        className="tool-integer"
        type="number"
        value={displaySize}
        onChange={handleSizeChange}
        onKeyDown={onlyIntInput}
        max={maxCursorSize}
      ></input> px</label>
      <input
        className="tool-range"
        type="range"
        onChange={handleRageChange}
        value={brushDiameter}
        min="1"
        max={maxCursorSize}
      ></input>

      {brushDiameter === 1 && 
        <label className="tool-label">
          Pixel Perfect:
          <input
            className="tool-checkbox"
            type="checkbox"
            onChange={(e) => handlePixelPerfect(e.target.checked)}
            checked={brushPixelPerfect}
          ></input>
        </label>
      }
      
      <label className="tool-label">
        Mode: <select
            className="tool-select"
            onChange={(event) => {handleModeChange(event)}}
            value={brushMode}
        >
            <option value="normal">Normal</option>
            <option value="dither">Dither</option>
            <option value="select">Select</option>
        </select>
      </label>

      {brushMode === "dither" && <>
        <label className="tool-label">
          Pressure: <select
              className="tool-select"
              onChange={(event) => {handlePressureModeChange(event)}}
              value={ditherPressureMode}
          >
            <option value="none">None</option>
            <option value="ratio">Ratio</option>
            <option value="diameter">Diameter</option>
          </select>
        </label>
        {ditherPressureMode !== "none" && <>
          <label className="tool-label"> Sensitivity: </label>
          <input
            className="tool-range"
            type="range"
            onChange={handlePressureFactor}
            value={displayFactor}
            min="1"
            max="9"
          ></input>
        </>}
        <label className="tool-label">Dither Ratio: </label>
        <input
          className="tool-range"
          type="range"
          onChange={handleDitherRatio}
          value={ditherRatio}
          min="0"
          max="16"
        ></input>
        <label className="tool-label">Offset X: <input
          className="tool-integer"
          type="number"
          value={displayOffsetX}
          onChange={handleOffsetXChange}
          onKeyDown={onlyIntInput}
          min={0}
          max={3}
        ></input></label>
        <label className="tool-label">Offset Y: <input
          className="tool-integer"
          type="number"
          value={displayOffsetY}
          onChange={handleOffsetYChange}
          onKeyDown={onlyIntInput}
          min={0}
          max={3}
        ></input></label>
      </>}
      {brushMode !== "dither" && <>
        <label className="tool-label">
          Pressure:
          <input
            className="tool-checkbox"
            type="checkbox"
            onChange={(e) => handleBrushPressure(e.target.checked)}
            checked={brushPressure}
          ></input>
        </label>
        {brushPressure && <>
          <label className="tool-label"> Sensitivity: </label>
          <input
            className="tool-range"
            type="range"
            onChange={handlePressureFactor}
            value={displayFactor}
            min="1"
            max="9"
          ></input>
        </>}
      </>}
      {/* {brushMode !== "dither" && <label className="tool-label">
        Select:
        <input
          className="tool-checkbox"
          type="checkbox"
          onChange={(e) => handleBrushSelect(e.target.checked)}
          checked={brushSelect}
        ></input> {metaKey}+P
      </label>} */}
    </>
  );
}

export default PencilTool;
