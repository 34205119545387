import { useEffect, useState } from "react";
import { apiUrl, defaultPalettes } from "../constants";
import axios from 'axios';
import "../css/ChangeSwatch.css";
import { useMainContext } from "../contexts/MainContext";

function ChangeSwatch({ onChange }) {
    const { palette, setSelectedSwatch } = useMainContext();

    const handleSwatchClick = (index) => {
        setSelectedSwatch(index);
        onChange();
    };

    return (
        <div className="change-swatch-wrapper">
            <h2 className="change-swatch-heading">Change Swatch</h2>
            <div className="load-palette-swatches">
                {palette && palette.map((swatch, index) => {
                    if(swatch) return (
                        <div
                            key={index}
                            className="change-swatch-swatch"
                            style={{ backgroundColor: swatch }}
                            onClick={() => handleSwatchClick(index)}
                        ></div>
                    );
                })}
                <div
                key={32}
                className="change-swatch-swatch alpha"
                onClick={() => handleSwatchClick(32)}
                ></div>
            </div>
        </div>
    );
}

export default ChangeSwatch;
