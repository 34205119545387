import "../css/ToolOptions.css";
import { useMainContext } from "../contexts/MainContext";

function EllipseTool() {
  const {
    ellipseAngle,
    setEllipseAngle
  } = useMainContext();

  const handleAngleChange = (e) => {
    setEllipseAngle(e.target.value);
  };

  return (
    <>
      <h4>Ellipse Tool</h4>
      <label className="tool-label">Angle: <input
        className="tool-float"
        type="number"
        step="22.5"
        value={ellipseAngle}
        onChange={handleAngleChange}
      ></input></label>
    </>
  );
}

export default EllipseTool;
