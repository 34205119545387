import { useState, useEffect, useRef } from "react";
import { apiUrl, maxResolution} from "../constants";
import axios from 'axios';
import "../css/NewDocument.css";
import { useUserContext } from "../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { storeDocumentData, canvasToBlob } from "../utils/document";
import { onlyIntInput} from "../utils/basics";
import previewBGFile from "../assets/images/preview-background.png";
import RgbQuant from 'rgbquant';
import chroma from "chroma-js";
import { rampPalette } from "../utils/drawing";

function NewDocument({ palettes, newTab, onCreate, num, group}) {
    const navigate = useNavigate();

    const [name, setName] = useState('Untitled');
    const [width, setWidth] = useState(64);
    const [height, setHeight] = useState(64);
    const [displayWidth, setDisplayWidth] = useState(width);
    const [displayHeight, setDisplayHeight] = useState(height);
    const [currentPalette, setCurrentPalette] = useState(0);
    const [imagePalette, setImagePalette] = useState(null); 
    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(true);

    const { user } = useUserContext();

    const nameInputRef = useRef(null);
    const previewBG = useRef(new Image());
    const input = useRef(null);

    useEffect(() => {
        if(num > 0){
            setName(`Untitled ${num}`)
        }
        // Automatically focus on the name input when the component is rendered
        if (nameInputRef.current) {
            nameInputRef.current.focus();
        }
        
        previewBG.current.onload = async () => {
            setLoading(false);
        };
        previewBG.current.src = previewBGFile;
    }, []);

    const handleNameChange = (e) => {
        setName(e.target.value.substring(0, 64));
    };
    const handleWidthChange = (e) => {
        if(e.target.value > maxResolution){ 
            setWidth(maxResolution);
            setDisplayWidth(maxResolution);
        } else if(e.target.value <= 0){
            setWidth(1);
            setDisplayWidth('');
        } else {
            setWidth(e.target.value);
            setDisplayWidth(e.target.value);
        }
    };
    const handleHeightChange = (e) => {
        if(e.target.value > maxResolution){ 
            setHeight(maxResolution); 
            setDisplayHeight(maxResolution);
        } else if(e.target.value <= 0){
            setHeight(1);
            setDisplayHeight('');
        } else {
            setHeight(e.target.value);
            setDisplayHeight(e.target.value);
        }
    };

    const handlePaletteChange = (e) => {
        if(e.target.value === "file"){
            setCurrentPalette(-1);
        } else {
            setCurrentPalette(e.target.value);
            setImagePalette(null);
        }
    }
    
    const handleBrowse = (e) => {
        e.preventDefault();
        input.current.click();
    };

    function handleUpload(e) {
        const file = e.target.files[0];
        if (!file) {
            return setErrorMessage("No file uploaded.");;
        }

        setImagePalette(null);
        setErrorMessage(null);

        const filename = file.name.toLowerCase();
        const extension = filename.split('.').pop();
        if(extension === "jpg" || extension === "jpeg" || extension === "png" || extension === "gif" || extension === "webp"){
            //upload file
            var img = new Image();
            img.onload = reduce;
            img.onerror = failed;
            img.src = URL.createObjectURL(file);
        } else if(extension === "txt"){
            const reader = new FileReader();
            reader.onload = function(e) {
                const content = e.target.result;
                const lines = content.split('\n');
                const newPalette = [];
            
                lines.forEach(line => {
                    line = line.trim();
                    if (line && !line.startsWith(';')) {
                        // Remove the leading FF if present
                        if (line.startsWith('FF')) {
                            line = line.slice(2, 8);
                        } else if(line.startsWith('#')){
                            line = line.slice(1, 7);
                        }
                        if(line !== '' && newPalette.length < 32) newPalette.push('#' + line);
                    }
                });
            
                setImagePalette(newPalette);
            };
            reader.readAsText(file);
        } else if (extension === "hex"){
            const reader = new FileReader();
            reader.onload = function(e) {
                const content = e.target.result;
                const lines = content.split('\n');
                const newPalette = [];
            
                lines.forEach(line => {
                    line = line.trim();
                    line = line.slice(0, 6);
                    if(line !== '' && newPalette.length < 32) newPalette.push('#' + line);
                });
            
                setImagePalette(newPalette);
            };
            reader.readAsText(file);
        } else {
            return setErrorMessage("File must be a JPG, PNG, GIF, WEBP, TXT, or HEX.");
        }
    };

    function reduce(){
        const canvas = document.createElement("canvas");
        canvas.width = this.width;
        canvas.height = this.height;
        const ctx = canvas.getContext("2d");
        ctx.imageSmoothingEnabled = false;
        ctx.drawImage(this, 0, 0, canvas.width, canvas.height);

        //get sampled palette
        const q = new RgbQuant({colors: 32});
        q.sample(canvas);
        const pal = q.palette(true, true);
        
        const newPalette = []

        for(const color of pal){
            newPalette.push(chroma(color).hex());
        }

        const rampedPalette = rampPalette(newPalette);

        setImagePalette(rampedPalette);
    }

    function failed(){
        return setErrorMessage("Error loading image.");
    }
    
    // Helper function to store document ID in local storage
    const handleSubmit = async (e) => {
        e.preventDefault();

        if(currentPalette === -1 && !imagePalette) return setErrorMessage("No palette Loaded.");
        if(!name) return setErrorMessage("Document name is required.");
        if(name.length > 64) return setErrorMessage("Document name must be 64 or less characters.");
        if(!/^[A-Za-z0-9_ -]+$/.test(name)) return setErrorMessage("Document name must only include letters, numbers, spaces, hyphens, and underscores.");
        if(width > maxResolution) return setErrorMessage("Width must be less than maxResolution px.");
        if(height > maxResolution) return setErrorMessage("Height must be less than maxResolution px.");
        
        const groupId = group ? group._id : null;

        const newPalette = currentPalette === -1 ? [...imagePalette] : [...palettes[currentPalette].palette];
        for(let i = newPalette.length; i < 32; i++){
            newPalette.push('');
        }

        const payload = {
            name: name.trim(),
            width,
            height,
            palette: newPalette,
            group: groupId
        };

        try {
            const response = await axios.post(apiUrl + "/api/newDocument", payload);
    
            if (response.status === 201) {
                const { token, docId } = response.data;
    
                // Store the document ID in local storage for all users
                storeDocumentData(docId, token);

                //save layers
                const imgData = new FormData();

                // Calculate the scaling factors for width and height
                const widthScale = 1200 / width;
                const heightScale = 627 / height;

                // Choose the minimum scaling factor to ensure the entire content fits within the target dimensions
                const scale = Math.min(widthScale, heightScale);

                //get canvas and ctx for outpt
                const canvas = document.createElement("canvas");
                canvas.width = width * scale;
                canvas.height = height * scale;
                const ctx = canvas.getContext("2d");
                ctx.imageSmoothingEnabled = false;
                
                ctx.drawImage(previewBG.current,0,0);

                const blob = await canvasToBlob(canvas);
                imgData.append("preview", blob, "preview.png");

                // Append token to the FormData
                imgData.append('token', token);

                await axios.post(`${apiUrl}/api/saveLayers/${docId}`, imgData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                
                onCreate();
    
                // Redirect to the document editor page
                if (newTab) {
                    return window.open(`/editor/${docId}`, '_blank', 'noopener,noreferrer');
                } else {
                    return navigate(`/editor/${docId}`);
                }
            }
        } catch (error) {   
            if (error.response && error.response.data && error.response.data.message) {
                return setErrorMessage(error.response.data.message);
            } else {
                console.error(error);
                return setErrorMessage("Error creating document.");
            }
        }
    };

    return (
        <div className="new-document-wrapper">
            <h2 className="new-document-heading">New Document</h2>
            <label className="new-document-label" htmlFor="name">Document Name:</label>
            <input ref={nameInputRef} id="name" className="new-document-input-name" value={name} onChange={handleNameChange} autoComplete="off" maxlength="64"/>
            <label className="new-document-label" htmlFor="width">Width:</label>
            <div className="new-document-input-wrapper">
                <input
                    id="width"
                    type="number"
                    className="new-document-input"
                    value={displayWidth}
                    onKeyDown={onlyIntInput}
                    onChange={handleWidthChange}
                    max={maxResolution}
                /> Max {maxResolution}px
            </div>
            <label className="new-document-label" htmlFor="height">Height:</label>
            <div className="new-document-input-wrapper">
                <input
                    id="height"
                    type="number"
                    className="new-document-input"
                    value={displayHeight}
                    onKeyDown={onlyIntInput}
                    onChange={handleHeightChange}
                    max={maxResolution}
                /> Max {maxResolution}px
            </div>
            <select
                name="palettes"
                id="palettes"
                className="new-document-select"
                value={currentPalette}
                onChange={(event) => {handlePaletteChange(event)}}
            >
                <option value="file">Load From File</option>
                {palettes && palettes.map((paletteItem, index) => (
                    <option key={index} value={index}>{paletteItem.name}</option>
                ))}
            </select>
            <div className="new-document-swatches">
                {palettes[currentPalette] && palettes[currentPalette].palette.map((swatch, index) => (
                    <div key={index} className="new-document-swatch" style={{ backgroundColor: swatch }}></div>
                ))}
                {currentPalette === -1 && imagePalette && imagePalette.map((swatch, index) => (
                    <div key={index} className="new-document-swatch" style={{ backgroundColor: swatch }}></div>
                ))}
            </div>
            {currentPalette === -1 &&
                <div className="load-palette-right">
                        <input type="file" onChange={handleUpload} ref={input} style={{display: 'none'}} accept=".jpg,.jpeg,.png,.gif,.webp,.txt,.hex" />
                        <button className="new-document-button" onClick={handleBrowse}>Browse</button>
                </div>
            }
            {errorMessage && <div className="new-document-error">{errorMessage}</div>}
            {!loading && <div className="new-document-bottom"><input type="submit" value="Create" onClick={(event) => {handleSubmit(event)}}/></div>}
        </div>
    );
}

export default NewDocument;
