import { useEffect, useState, useRef } from "react";
import "../css/Rename.css";
import axios from 'axios';
import { apiUrl } from "../constants";

function RenameHome({ docId, docName, group, onSave}) {
    const [name, setName] = useState(docName);
    const [errorMessage, setErrorMessage] = useState(null);
    
    const nameInputRef = useRef(null);

    useEffect(() => {
        setName(docName);
         // Automatically focus on the name input when the component is rendered
        if (nameInputRef.current) {
            nameInputRef.current.focus();
        }
    }, [docName]);

    const handleNameChange = (e) => {
        setName(e.target.value.substring(0, 32));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(!name) return setErrorMessage("Name is required.");
        if(name.length > 32) return setErrorMessage("Name must be 32 or less characters.");
        if(!/^[A-Za-z0-9 _-]+$/.test(name)) return setErrorMessage("Name contains invalid characters.");

        let response;
        if(group){
          // Retrieve the token from localStorage
          const groupDataStored = JSON.parse(localStorage.getItem('groupData')) || {};
          const token = groupDataStored[docId];
      
          response = await axios.post(apiUrl + "/api/renameGroup/" + docId, {token, name});
        } else {
          // Retrieve the token from localStorage
          const documentDataStored = JSON.parse(localStorage.getItem('documentData')) || {};
          const token = documentDataStored[docId];
      
          response = await axios.post(apiUrl + "/api/rename/" + docId, {token, name});
        }

        if(response.status === 200) {
            onSave(docId, name);
        } else {
            setErrorMessage("Error updating the name.")
        }
    };

    return (
        <div className="rename-wrapper">
            <h2 className="rename-heading">Rename Document</h2>
            <label className="rename-label" htmlFor="name">New Name:</label>
            <input  ref={nameInputRef} id="name" className="rename-input-name" value={name} onChange={handleNameChange} autoComplete="off"/>
            {errorMessage && <div className="rename-error">{errorMessage}</div>}
            <div className="rename-bottom"><input type="submit" value="Rename" onClick={(event) => {handleSubmit(event)}}/></div>
        </div>
    );
}

export default RenameHome;
