export const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const onlyIntInput = (e) => {
  const isNumber = /^[0-9]$/i.test(e.key);
  const isBackspace = e.keyCode === 8;
  const isDelete = e.keyCode === 46;
  const isArrow = e.keyCode >= 37 && e.keyCode <= 40;
  const isCtrl = e.ctrlKey || e.metaKey;

  // Allow Ctrl+V (paste), Ctrl+Z (undo), Ctrl+Y (redo), and Ctrl+A (select all)
  const isAllowedCombination = (isCtrl && ['v', 'z', 'y', 'a'].includes(e.key.toLowerCase()));

  if (!isNumber && !isBackspace && !isDelete && !isArrow && !isAllowedCombination) {
      e.preventDefault();
  }
};


export const onlyHexInput = (e) => {
    const isHexChar = /^[0-9A-F]$/i.test(e.key);
    const isBackspace = e.keyCode === 8;
    const isArrow = e.keyCode >= 37 && e.keyCode <= 40;
    
    if((!isBackspace && !isArrow ) && e.target.value.length >= 6){
        e.preventDefault();
    } else if (!isHexChar && !isBackspace && !isArrow) {
        e.preventDefault();
    }
};

export const decimalPlaces = (number) => {
    //  Convert the number to a string
    let numberStr = number.toString();
    
    // Check if the number contains a decimal point
    if (numberStr.includes('.')) {
      // Split the number into the integer and decimal parts
      let decimalPart = numberStr.split('.')[1];
      
      // Return the length of the decimal part
      return decimalPart.length;
    } else {
      // If there's no decimal point, return 0
      return 0;
    }
  }
