import "../css/ToolOptions.css";
import { useMainContext } from "../contexts/MainContext";

function MoveTool() {
  const { autoSelect, setAutoSelect } = useMainContext();

  const handleAutoSelect = (checked) => {
    setAutoSelect(checked);
  };

  return (
    <>
      <h4>Move Tool</h4>
      <label className="tool-label">
        Auto-Select:
        <input
          className="tool-checkbox"
          type="checkbox"
          onChange={(e) => handleAutoSelect(e.target.checked)}
          checked={autoSelect}
        ></input>
      </label>
    </>
  );
}

export default MoveTool;
