import { useState, useEffect } from "react";
import "../css/ToolOptions.css";
import { useMainContext } from "../contexts/MainContext";
import { maxCursorSize, metaKey } from "../constants";
import { onlyIntInput} from "../utils/basics";

function EraseTool() {
  const {
    eraseDiameter,
    setEraseDiameter,
    erasePixelPrefect,
    setErasePixelPerfect,
    eraseMode,
    setEraseMode,
    erasePressure,
    setErasePressure,
    eraseSelect,
    setEraseSelect,
    erasePressureFactor,
    setErasePressureFactor,
    eraseDitherRatio,
    setEraseDitherRatio,
    ditherOffsetX,
    setDitherOffsetX,
    ditherOffsetY,
    setDitherOffsetY,
    eraseDitherPressureMode,
    setEraseDitherPressureMode,
    invertEraseDither,
    setInvertEraseDither,
  } = useMainContext();

  const [displaySize, setDisplaySize] = useState(eraseDiameter);
  const [displayFactor, setDisplayFactor] = useState(10 - erasePressureFactor);
  const [displayOffsetX, setDisplayOffsetX] = useState(ditherOffsetX);
  const [displayOffsetY, setDisplayOffsetY] = useState(ditherOffsetY);

  const handleRageChange = (e) => {
    setEraseDiameter(parseInt(e.target.value));
  };

  const handleSizeChange = (e) => {
    if(e.target.value > maxCursorSize){
      setEraseDiameter(maxCursorSize); 
      setDisplaySize(maxCursorSize);
    } else if(e.target.value <= 0){
      setEraseDiameter(1);
      setDisplaySize('');
    } else {
      setEraseDiameter(parseInt(e.target.value));
      setDisplaySize(e.target.value);
    }
  };

  const handleOffsetXChange = (e) => {
    if(e.target.value > 3){
      setDitherOffsetX(3);
      setDisplayOffsetX(3);
    } else if(e.target.value < 0){
      setDitherOffsetX(0);
      setDisplayOffsetX('');
    } else {
      setDitherOffsetX(parseInt(e.target.value));
      setDisplayOffsetX(e.target.value);
    }
  };

  const handleOffsetYChange = (e) => {
    if(e.target.value > 3){
      setDitherOffsetY(3);
      setDisplayOffsetY(3);
    } else if(e.target.value < 0){
      setDitherOffsetY(0);
      setDisplayOffsetY('');
    } else {
      setDitherOffsetY(parseInt(e.target.value));
      setDisplayOffsetY(e.target.value);
    }
  };

  const handlePressureFactor = (e) => {
    setErasePressureFactor(9 - e.target.value);
    setDisplayFactor(e.target.value);
  };

  useEffect(() =>{
    setDisplaySize(eraseDiameter);
  }, [eraseDiameter]);

  const handleErasePressure = (checked) => {
    setErasePressure(checked);
  };

  const handleEraseSelect = (checked) => {
    setEraseSelect(checked);
  };

  const handleModeChange = (e) => {
      setEraseMode(e.target.value);
  };

  const handleDitherRatio = (e) => {
    setEraseDitherRatio(e.target.value);
  };

  const handlePressureModeChange = (e) => {
      setEraseDitherPressureMode(e.target.value);
  };

const handlePixelPerfect = (checked) => {
  setErasePixelPerfect(checked);
};

  const handleInvert = (checked) => {
    setInvertEraseDither(checked);
  };

  return (
    <>
      <h4>Eraser Tool</h4>
      <label className="tool-label">Size: <input
        className="tool-integer"
        type="number"
        value={displaySize}
        onChange={handleSizeChange}
        onKeyDown={onlyIntInput}
        max={maxCursorSize}
      ></input> px</label>
      <input
        className="tool-range"
        type="range"
        onChange={handleRageChange}
        value={eraseDiameter}
        min="1"
        max={maxCursorSize}
      ></input>
      
      {eraseDiameter === 1 && 
        <label className="tool-label">
          Pixel Perfect:
          <input
            className="tool-checkbox"
            type="checkbox"
            onChange={(e) => handlePixelPerfect(e.target.checked)}
            checked={erasePixelPrefect}
          ></input>
        </label>
      }

      <label className="tool-label">
        Mode: <select
            className="tool-select"
            onChange={(event) => {handleModeChange(event)}}
            value={eraseMode}
        >
            <option value="normal">Normal</option>
            <option value="dither">Dither</option>
            <option value="select">Deselect</option>
        </select>
      </label>

      {eraseMode === "dither" && <>
        <label className="tool-label">
          Pressure: <select
              className="tool-select"
              onChange={(event) => {handlePressureModeChange(event)}}
              value={eraseDitherPressureMode}
          >
            <option value="none">None</option>
            <option value="ratio">Ratio</option>
            <option value="diameter">Diameter</option>
          </select>
        </label>
        {eraseDitherPressureMode !== "none" && <>
          <label className="tool-label"> Sensitivity: </label>
          <input
            className="tool-range"
            type="range"
            onChange={handlePressureFactor}
            value={displayFactor}
            min="1"
            max="9"
          ></input>
        </>}
        <label className="tool-label">Dither Ratio: </label>
        <input
          className="tool-range"
          type="range"
          onChange={handleDitherRatio}
          value={eraseDitherRatio}
          min="0"
          max="16"
        ></input>
        <label className="tool-label">
          Invert:
          <input
            className="tool-checkbox"
            type="checkbox"
            onChange={(e) => handleInvert(e.target.checked)}
            checked={invertEraseDither}
          ></input>
        </label>
        <label className="tool-label">Offset X: <input
          className="tool-integer"
          type="number"
          value={displayOffsetX}
          onChange={handleOffsetXChange}
          onKeyDown={onlyIntInput}
          min={0}
          max={3}
        ></input></label>
        <label className="tool-label">Offset Y: <input
          className="tool-integer"
          type="number"
          value={displayOffsetY}
          onChange={handleOffsetYChange}
          onKeyDown={onlyIntInput}
          min={0}
          max={3}
        ></input></label>
      </>}

      {erasePressure && eraseMode !== "dither" && <>
        <label className="tool-label">
          Pressure:
          <input
            className="tool-checkbox"
            type="checkbox"
            onChange={(e) => handleErasePressure(e.target.checked)}
            checked={erasePressure}
          ></input>
        </label>
        <label className="tool-label"> Sensitivity: </label>
        <input
          className="tool-range"
          type="range"
          onChange={handlePressureFactor}
          value={displayFactor}
          min="1"
          max="9"
        ></input>
      </>}
      {/* {eraseMode !== "dither" && <label className="tool-label">
        Select:
        <input
          className="tool-checkbox"
          type="checkbox"
          onChange={(e) => handleEraseSelect(e.target.checked)}
          checked={eraseSelect}
        ></input> {metaKey}+E
      </label>} */}
    </>
  );
}

export default EraseTool;
