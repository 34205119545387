import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes 
} from 'react-router-dom';
import axios from 'axios';
import "./css/Editor.css";
import { apiUrl } from "./constants";
import { useUserContext } from "./contexts/UserContext";
import Home from './components/Home';
import Dashboard from './components/Dashboard';
import Guide from './components/Guide';
import Support from './components/Support';
import VerifyEmail from './components/VerifyEmail';
import ResetPassword from './components/ResetPassword';
import Editor from './components/Editor';
import NotFound from './components/NotFound';
import InternalError from './components/InternalError';
import CookieConsentFooter from './components/CookieConsentFooter';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import Unsubscribe from './components/Unsubscribe';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactGA from 'react-ga4';

// Your Google Analytics 4 measurement ID
const measurementId = 'G-GLW5TR9K2X';

// Initialize GA4 with your measurement ID
ReactGA.initialize(measurementId);

function App() {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

  const { setUser } = useUserContext();

  const [showCookieConsent, setShowCookieConsent] = useState(false);

  useEffect(() => {
      const consent = localStorage.getItem('cookieConsentGiven');
      if (!consent) {
          setShowCookieConsent(true);
      }
  }, []);

  const handleCookieConsent = () => {
      localStorage.setItem('cookieConsentGiven', 'true');
      setShowCookieConsent(false);
  };

  axios.defaults.withCredentials = true;

  useEffect(() => {
    axios.post(apiUrl + '/api/auth/refreshToken')
      .then(response => {
        if (response.status === 200) {
          // Update UserContext
          setUser(response.data.userData);
        }
      })
      .catch(error => {
        console.error('Error refreshing token:', error);
      });
  }, []);

  axios.interceptors.response.use(response => {
    return response;
  }, error => {
    const originalRequest = error.config;
    if ((error.response && error.response.status === 401) && !originalRequest._retry && originalRequest.url !== apiUrl + '/api/auth/refreshToken') {
      originalRequest._retry = true;
      return axios.post(apiUrl + '/api/auth/refreshToken') // Send refresh token request
        .then(response => {
          if (response.status === 200) {
            // Update header for the original request
            originalRequest.headers['Authorization'] = 'Bearer ' + response.data.accessToken;

            setUser(response.data.userData);
  
            // Return the original request
            return axios(originalRequest);
          }
        });
    }
    return Promise.reject(error);
  });  

  return (
    <div className="app grow">
      <Router>
        <div className="content grow">
          <Routes >
            <Route index element={<Home />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/guide" element={<Guide />} />
            <Route path="/support" element={<Support />} />
            <Route path="verify-email/:username/:token" element={<VerifyEmail />} />
            <Route path="reset-password/:username/:token" element={<ResetPassword />} />
            <Route path='/editor/:id' element={<Editor />} />
            <Route path='/500' element={<InternalError />} />
            <Route path='/terms' element={<Terms />} />
            <Route path='/privacy' element={<Privacy />} />
            <Route path='/unsubscribe/:email/:token' element={<Unsubscribe />} />
            <Route path='*' element={<NotFound />} />
          </Routes >
        </div>
      </Router>
      {showCookieConsent && <CookieConsentFooter onAccept={handleCookieConsent} />}
    </div>
  );
}

export default App;