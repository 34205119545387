import { useState } from "react";
import { apiUrl } from "../constants";
import { useParams } from "react-router-dom";
import axios from 'axios';
import "../css/ResetPassword.css"; // Make sure to create appropriate CSS

function ResetPassword() {
    const { token } = useParams(); // Assuming the token is passed in the URL
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');

    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const resetPassword = (e) => {
        e.preventDefault();

        setError(null);

        let error = [];

        if(!newPassword){
            error.push(<p className="error-message">Password is required.</p>);
        } else if(newPassword.length < 8) {
            error.push(<p className="error-message">Password must be 8 characters or more.</p>);
        } else if(newPassword.length > 32) {
            error.push(<p className="error-message">Password must be 32 characters or less.</p>);
        } else{
            if(!/.*\d+.*/.test(newPassword)){
                error.push(<p className="error-message">Password must contain a number.</p>);
            }
            if(!/.*[a-z].*/.test(newPassword)){
                error.push(<p className="error-message">Password must contain a lower case letter.</p>);
            }
            if(!/.*[A-Z].*/.test(newPassword)){
                error.push(<p className="error-message">Password must contain a capital letter.</p>);
            }
            if(!/.*[@$!%*#?&^+].*/.test(newPassword)){
                error.push(<p className="error-message">Password must contain a special character.</p>);
            }
        }

        if (newPassword !== confirmPassword) {
            error.push(<p className="error-message">Passwords do not match.</p>);
        }

        if(error.length > 0) return setError(error);

        axios.post(apiUrl + "/api/auth/resetPassword", { token, newPassword })
            .then(response => {
                setSuccess(true);
                setError(null);
            })
            .catch(error => {
                // Check if the error response contains a custom message from the server
                if (error.response && error.response.data && error.response.data.message) {
                    setError(<p className="error-message">{error.response.data.message}</p>);
                } 
                setSuccess(false);
            });
    };

    return (
        <div className="reset-password-wrapper">
            {success ?
                <p className="success-message">Your password has been reset! <a href='/'>Home</a></p>
                :
                <form className="reset-password-form" onSubmit={resetPassword}>
                    <h2 className="password-heading">Reset Password</h2>
                    {error && <div>{error}</div>}
                    <input
                        type="password"
                        className="password-input"
                        value={newPassword}
                        onChange={handleNewPasswordChange}
                        placeholder="New Password"
                        autoFocus
                    />
                    <input
                        type="password"
                        className="password-input"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        placeholder="Confirm New Password"
                    />
                    <div className="input-right">
                        <input type="submit" value="Reset Password"></input>
                    </div>
                </form>
            }
        </div>
    );
}

export default ResetPassword;
