import { useState, useEffect } from "react";
import "../css/ToolOptions.css";
import { useMainContext } from "../contexts/MainContext";
import { maxCursorSize, metaKey} from "../constants";
import { onlyIntInput} from "../utils/basics";

function TransformTool() {
  const { setApplyTransformNow, setResetTransformNow} = useMainContext();

  return (
    <>
      <h4>Transform Tool</h4>
      <div>
        <button className="crop-button" onClick={() =>{
          setResetTransformNow((prevValue) => prevValue + 1);
        }}>Reset</button>
        <button className="crop-button" onClick={() =>{
          setApplyTransformNow((prevValue) => prevValue + 1);
        }}>Apply</button>
      </div>
    </>
  );
}

export default TransformTool;
