import React, { useEffect, useState, useRef, useCallback } from "react";
import { useMainContext } from "../contexts/MainContext";
import "../css/Layers.css";
import { maxLayerCount } from "../constants";
import lineIcon from '../assets/images/line.svg';
import perspectiveIcon from '../assets/images/perspective.svg';
import twoPointPerspectiveIcon from '../assets/images/two-point-perspective.svg';
import threePointPerspectiveIcon from '../assets/images/three-point-perspective.svg';
import gridIcon from '../assets/images/grid.svg';
import isometricIcon from '../assets/images/isometric.svg';
import ellipseIcon from '../assets/images/ellipse.svg';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { distance } from "../utils/drawing";

function Layers() {
  const {
    resolution,
    offset,
    layers,
    setLayers,
    selectedLayer,
    setSelectedLayer,
    selectedLayers, 
    setSelectedLayers,
    magnetLayer, 
    setMagnetLayer,
    addLayer,
    deleteLayer,
    reorderLayers,
    mergeDown,
    shiftKey,
    altKey,
    altButton,
    setAltButton,
    shiftButton,
    setShiftButton,
    metaButton,
    setMetaButton,
    layerSelect,
    layerSelectAdd,
    layerSelectRemove,
    layerSelectIn,
    version,
    selectedTool,
    setSelectedTool,
    previousTool,
    setPreviousTool,
    loading,
    setLoading,
  } = useMainContext();

  const floatingLayer = useRef(null);
  const selectedInput = useRef(null);

  const [canvases, setCanvases] = useState([]);
  const [aspectRatio, setAspectRatio] = useState(resolution.height / resolution.width);
  const [draggingLayer, setDraggingLayer] = useState(null);
  const [clickLayer, setClickLayer] = useState(null);
  const [moveAbove, setMoveAbove] = useState(null);
  const [renameLayer, setRenameLayer] = useState(null);
  const [cursorPath, setCursorPath] = useState(null);
  const [svgWidth, setSvgWidth] = useState(null);
  const [svgHeight, setSvgHeight] = useState(null);

  const shiftRef = useRef(false);
  const altRef = useRef(false);
  const metaRef = useRef(false);
  const corsurCoordinate = useRef(null);
  const dragPosition = useRef({ x: 0, y: 0 });
  const touches = useRef([]);
  const layersWrapperInnerRef = useRef(null);
  const layersWrapperOuterRef = useRef(null);
  const scrollFrameRef = useRef(null);
  const scrolling = useRef(false);
  const startPosition = useRef(null);
  const dragInitTimer = useRef(null);


  useEffect(() => {
    setAspectRatio(resolution.height / resolution.width);
  }, [resolution]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setSvgWidth(entry.contentRect.width);
        setSvgHeight(entry.contentRect.height);
      }
    });

    // observe one or more elements
    if (layersWrapperOuterRef.current) {
      resizeObserver.observe(layersWrapperOuterRef.current);
    }

    return () => {
      // Cleanup
      if (layersWrapperOuterRef.current) {
        resizeObserver.unobserve(layersWrapperOuterRef.current);
        resizeObserver.disconnect();
      }
    };
  }, []);

  const handleCanvasMove = (e) => {
    const rect = layersWrapperInnerRef.current.getBoundingClientRect();
    corsurCoordinate.current = {x: e.clientX - rect.left, y: e.clientY - rect.top };
  };
  const handleCanvasOut = (e) => {
    corsurCoordinate.current = null;
    setCursorPath(null);
  }

  useEffect(() => {
    const handleKeyDown = (e) => {
      if(e.keyCode === 18 || altButton) altRef.current = true;
      if (e.keyCode === 16 || shiftButton) shiftRef.current = true;
      if(e.ctrlKey || e.metaKey || metaButton) metaRef.current = true;

      if(e.keyCode === 27){
        setDraggingLayer(null);
      }

      if(!corsurCoordinate.current) return setCursorPath(null);
      const offset = 8;
      const lineLength = 8;
      const x = corsurCoordinate.current.x;
      const y = corsurCoordinate.current.y;

      if(metaRef.current){
        if(shiftRef.current){
          if(altRef.current){
            setCursorPath((
              <>
                <line
                  key="plus-diag1-white"
                  x1={x + lineLength + offset}
                  x2={x + lineLength * 2 + offset}
                  y1={y + lineLength * 2 + offset}
                  y2={y + lineLength + offset}
                  stroke="white"
                  strokeWidth={4 / window.devicePixelRatio}
                  vectorEffect="non-scaling-stroke"
                  strokeLinecap="round"
                />
                <line
                  key="plus-diag2-white"
                  x1={x + lineLength + offset}
                  x2={x + lineLength * 2 + offset}
                  y1={y + lineLength + offset}
                  y2={y + lineLength * 2 + offset}
                  stroke="white"
                  strokeWidth={4 / window.devicePixelRatio}
                  vectorEffect="non-scaling-stroke"
                  strokeLinecap="round"
                />
                <line
                  key="plus-diag1-black"
                  x1={x + lineLength + offset}
                  x2={x + lineLength * 2 + offset}
                  y1={y + lineLength * 2 + offset}
                  y2={y + lineLength + offset}
                  stroke="black"
                  strokeWidth={2 / window.devicePixelRatio}
                  vectorEffect="non-scaling-stroke"
                />
                <line
                  key="plus-diag2-black"
                  x1={x + lineLength + offset}
                  x2={x + lineLength * 2 + offset}
                  y1={y + lineLength + offset}
                  y2={y + lineLength * 2 + offset}
                  stroke="black"
                  strokeWidth={2 / window.devicePixelRatio}
                  vectorEffect="non-scaling-stroke"
                />
              </>
            ));
          } else{
            setCursorPath((
              <>
                <line
                  key="plus-horizontal-white"
                  x1={x + lineLength + offset}
                  x2={x + lineLength * 2 + offset}
                  y1={y + lineLength * 1.5 + offset}
                  y2={y + lineLength * 1.5 + offset}
                  stroke="white"
                  strokeWidth={4 / window.devicePixelRatio}
                  vectorEffect="non-scaling-stroke"
                  strokeLinecap="round"
                />
                <line
                  key="plus-verticle-white"
                  x1={x + lineLength * 1.5 + offset}
                  x2={x + lineLength * 1.5 + offset}
                  y1={y + lineLength + offset}
                  y2={y + lineLength * 2 + offset}
                  stroke="white"
                  strokeWidth={4 / window.devicePixelRatio}
                  vectorEffect="non-scaling-stroke"
                  strokeLinecap="round"
                />
                <line
                  key="plus-horizontal-black"
                  x1={x + lineLength + offset}
                  x2={x + lineLength * 2 + offset}
                  y1={y + lineLength * 1.5 + offset}
                  y2={y + lineLength * 1.5 + offset}
                  stroke="black"
                  strokeWidth={2 / window.devicePixelRatio}
                  vectorEffect="non-scaling-stroke"
                />
                <line
                  key="plus-verticle-black"
                  x1={x + lineLength * 1.5 + offset}
                  x2={x + lineLength * 1.5 + offset}
                  y1={y + lineLength + offset}
                  y2={y + lineLength * 2 + offset}
                  stroke="black"
                  strokeWidth={2 / window.devicePixelRatio}
                  vectorEffect="non-scaling-stroke"
                />
              </>
            ));
          }
        } else if (altRef.current){
          setCursorPath((
            <>
              <line
                key="minus-white"
                x1={x + lineLength + offset}
                x2={x + lineLength * 2 + offset}
                y1={y + lineLength * 1.5 + offset}
                y2={y + lineLength * 1.5 + offset}
                stroke="white"
                strokeWidth={4 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"        
              />
              <line
                key="minus-black"
                x1={x + lineLength + offset}
                x2={x + lineLength * 2 + offset}
                y1={y + lineLength * 1.5 + offset}
                y2={y + lineLength * 1.5 + offset}
                stroke="black"
                strokeWidth={2 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
              />
            </>
          ));
        } else {
          setCursorPath((
            <>
              <rect
                key="all-white"
                x={x + lineLength + offset}
                y={y + lineLength + offset}
                width={lineLength}
                height={lineLength}
                stroke="white"
                fill="transparent" 
                strokeWidth={4 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
              />
              <rect
                key="all-black"
                x={x + lineLength + offset}
                y={y + lineLength + offset}
                width={lineLength}
                height={lineLength}
                stroke="black"
                fill="transparent" 
                strokeWidth={2 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
              />
            </>
          ));
        }
      }
    };

    const handleKeyUp = (e) => {
      if(e.keyCode === 18 && !altButton) altRef.current = false;
      if (e.keyCode === 16 && !shiftButton) shiftRef.current = false;
      if(!e.ctrlKey && !e.metaKey && !metaButton) metaRef.current = false;

      if(!corsurCoordinate.current) return setCursorPath(null);
      const offset = 8;
      const lineLength = 8;
      const x = corsurCoordinate.current.x;
      const y = corsurCoordinate.current.y;

      if(!metaRef.current){
        setCursorPath(null);
      } else if(shiftRef.current){
        if(altRef.current){
          setCursorPath((
            <>
              <line
                key="plus-diag1-white"
                x1={x + lineLength + offset}
                x2={x + lineLength * 2 + offset}
                y1={y + lineLength * 2 + offset}
                y2={y + lineLength + offset}
                stroke="white"
                strokeWidth={4 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
              />
              <line
                key="plus-diag2-white"
                x1={x + lineLength + offset}
                x2={x + lineLength * 2 + offset}
                y1={y + lineLength + offset}
                y2={y + lineLength * 2 + offset}
                stroke="white"
                strokeWidth={4 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
              />
              <line
                key="plus-diag1-black"
                x1={x + lineLength + offset}
                x2={x + lineLength * 2 + offset}
                y1={y + lineLength * 2 + offset}
                y2={y + lineLength + offset}
                stroke="black"
                strokeWidth={2 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
              />
              <line
                key="plus-diag2-black"
                x1={x + lineLength + offset}
                x2={x + lineLength * 2 + offset}
                y1={y + lineLength + offset}
                y2={y + lineLength * 2 + offset}
                stroke="black"
                strokeWidth={2 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
              />
            </>
          ));
        } else{
          setCursorPath((
            <>
              <line
                key="plus-horizontal-white"
                x1={x + lineLength + offset}
                x2={x + lineLength * 2 + offset}
                y1={y + lineLength * 1.5 + offset}
                y2={y + lineLength * 1.5 + offset}
                stroke="white"
                strokeWidth={4 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
              />
              <line
                key="plus-verticle-white"
                x1={x + lineLength * 1.5 + offset}
                x2={x + lineLength * 1.5 + offset}
                y1={y + lineLength + offset}
                y2={y + lineLength * 2 + offset}
                stroke="white"
                strokeWidth={4 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
              />
              <line
                key="plus-horizontal-black"
                x1={x + lineLength + offset}
                x2={x + lineLength * 2 + offset}
                y1={y + lineLength * 1.5 + offset}
                y2={y + lineLength * 1.5 + offset}
                stroke="black"
                strokeWidth={2 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
              />
              <line
                key="plus-verticle-black"
                x1={x + lineLength * 1.5 + offset}
                x2={x + lineLength * 1.5 + offset}
                y1={y + lineLength + offset}
                y2={y + lineLength * 2 + offset}
                stroke="black"
                strokeWidth={2 / window.devicePixelRatio}
                vectorEffect="non-scaling-stroke"
              />
            </>
          ));
        }
      } else if (altRef.current){
        setCursorPath((
          <>
            <line
              key="minus-white"
              x1={x + lineLength + offset}
              x2={x + lineLength * 2 + offset}
              y1={y + lineLength * 1.5 + offset}
              y2={y + lineLength * 1.5 + offset}
              stroke="white"
              strokeWidth={4 / window.devicePixelRatio}
              vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
            />
            <line
              key="minus-black"
              x1={x + lineLength + offset}
              x2={x + lineLength * 2 + offset}
              y1={y + lineLength * 1.5 + offset}
              y2={y + lineLength * 1.5 + offset}
              stroke="black"
              strokeWidth={2 / window.devicePixelRatio}
              vectorEffect="non-scaling-stroke"
            />
          </>
        ));
      } else {
        setCursorPath((
          <>
            <rect
              key="all-white"
              x={x + lineLength + offset}
              y={y + lineLength + offset}
              width={lineLength}
              height={lineLength}
              stroke="white"
              fill="transparent" 
              strokeWidth={4 / window.devicePixelRatio}
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"  
            />
            <rect
              key="all-black"
              x={x + lineLength + offset}
              y={y + lineLength + offset}
              width={lineLength}
              height={lineLength}
              stroke="black"
              fill="transparent" 
              strokeWidth={2 / window.devicePixelRatio}
              vectorEffect="non-scaling-stroke"
            />
          </>
        ));
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const handleLayerPointerDown = useCallback((e, index) => {
    if(e.target.tagName.toLowerCase() === "span") return;
    if(e.target.tagName.toLowerCase() === "button") return;
    e.preventDefault();

    startPosition.current = { x: e.clientX, y: e.clientY };
    dragPosition.current = { x: e.clientX, y: e.clientY };
    if(e.pointerType === "touch"){
      dragInitTimer.current = setTimeout(() => {
        if(layersWrapperInnerRef.current) layersWrapperInnerRef.current.style.overflowY = 'hidden';
        setDraggingLayer(index);
      }, 500);
    } else {
      setClickLayer(index);
    }
  }, []);

  const handleLayerClick = useCallback((e, index) => {
    if(e.target.tagName.toLowerCase() === "button") return;
    if(e.target.tagName.toLowerCase() === "span") return;
    if(e.target.tagName.toLowerCase() === "canvas"){
      if(e.ctrlKey || e.metaKey || metaButton){
        setLoading(true);
        setTimeout(() => {
          if(shiftKey || shiftButton){
            if(altKey || altButton){
              layerSelectIn(index);
            } else {
              layerSelectAdd(index);
            }
          } else if(altKey || altButton){
            layerSelectRemove(index);
          } else {
            layerSelect(index);
          }
          setLoading(false);
        }, 100);
      } else if(shiftKey || shiftButton){
        const newSelectedLayers = [...selectedLayers];
        if(selectedLayer < index){
          for(let i = selectedLayer; i <= index; i++){
            if(!selectedLayers.includes(i)) newSelectedLayers.push(i);
          }
        } else if(selectedLayer > index){
          for(let i = selectedLayer; i >= index; i--){
            if(!selectedLayers.includes(i)) newSelectedLayers.push(i);
          }
        }
        setSelectedLayers([...newSelectedLayers]);
        setSelectedLayer(index);

        if(layers[index].type !== "Canvas"){
          setSelectedTool(prevTool => {
            if(prevTool !== "guide") setPreviousTool(prevTool);      
            return "guide";
          });
        } else if(selectedTool === "guide"){
          setSelectedTool(previousTool);
        }
      } else {
        setSelectedLayer(index);
        setSelectedLayers([index]);
        
        if(layers[index].type !== "Canvas"){
          setSelectedTool(prevTool => {
            if(prevTool !== "guide") setPreviousTool(prevTool);      
            return "guide";
          });
        } else if(selectedTool === "guide"){
          setSelectedTool(previousTool);
        }
      }
    } else {
      if(shiftKey || shiftButton){
        const newSelectedLayers = [...selectedLayers];
        if(selectedLayer < index){
          for(let i = selectedLayer; i <= index; i++){
            if(!selectedLayers.includes(i)) newSelectedLayers.push(i);
          }
        } else if(selectedLayer > index){
          for(let i = selectedLayer; i >= index; i--){
            if(!selectedLayers.includes(i)) newSelectedLayers.push(i);
          }
        }
        setSelectedLayers([...newSelectedLayers]);
        setSelectedLayer(index);
        
        if(layers[index].type !== "Canvas"){
          setSelectedTool(prevTool => {
            if(prevTool !== "guide") setPreviousTool(prevTool);      
            return "guide";
          });
        } else if(selectedTool === "guide"){
          setSelectedTool(previousTool);
        }
      } else if(e.ctrlKey || e.metaKey || metaButton){
        const newSelectedLayers = [...selectedLayers];
        if(selectedLayers.includes(index)){
          if(newSelectedLayers.length > 1){
            newSelectedLayers.splice(newSelectedLayers.indexOf(index),1);
            setSelectedLayer(newSelectedLayers[newSelectedLayers.length - 1]);
          }
        } else {
          newSelectedLayers.push(index);
          setSelectedLayer(index);
        }
        setSelectedLayers([...newSelectedLayers]);
        
        if(layers[index].type !== "Canvas"){
          setSelectedTool(prevTool => {
            if(prevTool !== "guide") setPreviousTool(prevTool);      
            return "guide";
          });
        } else if(selectedTool === "guide"){
          setSelectedTool(previousTool);
        }
      } else {
        setSelectedLayers([index]);
        setSelectedLayer(index);
        
        if(layers[index].type !== "Canvas"){
          setSelectedTool(prevTool => {
            if(prevTool !== "guide") setPreviousTool(prevTool);      
            return "guide";
          });
        } else if(selectedTool === "guide"){
          setSelectedTool(previousTool);
        }
      };
    }
  }, [layers, selectedTool, previousTool, selectedLayer, selectedLayers,  draggingLayer, moveAbove, canvases, aspectRatio, reorderLayers, shiftKey, altKey, shiftButton, altButton, metaButton]);

  const handleAutoScroll = () => {
    if (scrolling.current) {
      cancelAnimationFrame(scrollFrameRef.current);
      scrollFrameRef.current = null;
      return;
    }

    const layersWrapperOuter = layersWrapperOuterRef.current;
    const layersWrapperInner = layersWrapperInnerRef.current;
    if (!layersWrapperInner || !layersWrapperOuter) return;

    const { top, bottom } = layersWrapperOuter.getBoundingClientRect();
    const scrollSpeed = window.screen.height / 192;
    const threshold = 48;
    
    if (dragPosition.current.y < top + threshold) {
      layersWrapperInner.scrollTop -= scrollSpeed;
    } else if (dragPosition.current.y > bottom - threshold - 51) {
      layersWrapperInner.scrollTop += scrollSpeed;
    }
    
    scrollFrameRef.current = requestAnimationFrame(handleAutoScroll);
  };

  useEffect(() => {
    const handleTouchMove = (e) => {
      const touch = e.touches[0];
      
      dragPosition.current = { x: touch.clientX, y: touch.clientY };

      if (draggingLayer !== null) {
        if (layers[draggingLayer].type === "Canvas"){
          // Draw the floating layer
          const ctx = floatingLayer.current.getContext("2d");
          ctx.clearRect(0, 0, 64, Math.round(64 * aspectRatio));
          ctx.drawImage(
            canvases[draggingLayer].current, // Change from selectedLayer to draggingLayer
            0,
            0,
            64,
            Math.round(64 * aspectRatio)
          );
        }

        // Update the position of the floating layer
        
        // Start auto-scrolling{
        if(!scrollFrameRef.current) scrollFrameRef.current = requestAnimationFrame(handleAutoScroll);

        for (let i = canvases.length - 1; i >= 0; i--) {
          const layerRect = canvases[i].current.getBoundingClientRect();
          if (touch.clientY < layerRect.top) {
            setMoveAbove(i + 1);
            return;
          }
        }
        setMoveAbove(0);
      } else {
        if(startPosition.current && distance(startPosition.current, { x: touch.clientX, y: touch.clientY }) > 5){
          clearTimeout(dragInitTimer.current);
          if(layersWrapperInnerRef.current) layersWrapperInnerRef.current.style.overflowY = 'auto';
        }
        if (scrollFrameRef.current) {
          cancelAnimationFrame(scrollFrameRef.current);
          scrollFrameRef.current = null;
        }
      }
    };

    const handlePointerMove = (e) => {
      if(e.pointerType === 'touch') return;

      altRef.current = altKey || altButton ?  true : false;
      shiftRef.current = shiftKey || shiftButton ? true : false;
      metaRef.current = e.ctrlKey || e.metaKey || metaButton ? true : false;

      if(!corsurCoordinate.current){
        setCursorPath(null);
      } else {
        const offset = 8;
        const lineLength = 8;
        const x = corsurCoordinate.current.x;
        const y = corsurCoordinate.current.y;
  
        if(metaRef.current){
          if(shiftRef.current){
            if(altRef.current){
              setCursorPath((
                <>
                  <line
                    key="plus-diag1-white"
                    x1={x + lineLength + offset}
                    x2={x + lineLength * 2 + offset}
                    y1={y + lineLength * 2 + offset}
                    y2={y + lineLength + offset}
                    stroke="white"
                    strokeWidth={4 / window.devicePixelRatio}
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                  />
                  <line
                    key="plus-diag2-white"
                    x1={x + lineLength + offset}
                    x2={x + lineLength * 2 + offset}
                    y1={y + lineLength + offset}
                    y2={y + lineLength * 2 + offset}
                    stroke="white"
                    strokeWidth={4 / window.devicePixelRatio}
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                  />
                  <line
                    key="plus-diag1-black"
                    x1={x + lineLength + offset}
                    x2={x + lineLength * 2 + offset}
                    y1={y + lineLength * 2 + offset}
                    y2={y + lineLength + offset}
                    stroke="black"
                    strokeWidth={2 / window.devicePixelRatio}
                    vectorEffect="non-scaling-stroke"
                  />
                  <line
                    key="plus-diag2-black"
                    x1={x + lineLength + offset}
                    x2={x + lineLength * 2 + offset}
                    y1={y + lineLength + offset}
                    y2={y + lineLength * 2 + offset}
                    stroke="black"
                    strokeWidth={2 / window.devicePixelRatio}
                    vectorEffect="non-scaling-stroke"
                  />
                </>
              ));
            } else{
              setCursorPath((
                <>
                  <line
                    key="plus-horizontal-white"
                    x1={x + lineLength + offset}
                    x2={x + lineLength * 2 + offset}
                    y1={y + lineLength * 1.5 + offset}
                    y2={y + lineLength * 1.5 + offset}
                    stroke="white"
                    strokeWidth={4 / window.devicePixelRatio}
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                  />
                  <line
                    key="plus-verticle-white"
                    x1={x + lineLength * 1.5 + offset}
                    x2={x + lineLength * 1.5 + offset}
                    y1={y + lineLength + offset}
                    y2={y + lineLength * 2 + offset}
                    stroke="white"
                    strokeWidth={4 / window.devicePixelRatio}
                    vectorEffect="non-scaling-stroke"
                    strokeLinecap="round"
                  />
                  <line
                    key="plus-horizontal-black"
                    x1={x + lineLength + offset}
                    x2={x + lineLength * 2 + offset}
                    y1={y + lineLength * 1.5 + offset}
                    y2={y + lineLength * 1.5 + offset}
                    stroke="black"
                    strokeWidth={2 / window.devicePixelRatio}
                    vectorEffect="non-scaling-stroke"
                  />
                  <line
                    key="plus-verticle-black"
                    x1={x + lineLength * 1.5 + offset}
                    x2={x + lineLength * 1.5 + offset}
                    y1={y + lineLength + offset}
                    y2={y + lineLength * 2 + offset}
                    stroke="black"
                    strokeWidth={2 / window.devicePixelRatio}
                    vectorEffect="non-scaling-stroke"
                  />
                </>
              ));
            }
          } else if (altRef.current){
            setCursorPath((
              <>
                <line
                  key="minus-white"
                  x1={x + lineLength + offset}
                  x2={x + lineLength * 2 + offset}
                  y1={y + lineLength * 1.5 + offset}
                  y2={y + lineLength * 1.5 + offset}
                  stroke="white"
                  strokeWidth={4 / window.devicePixelRatio}
                  vectorEffect="non-scaling-stroke"
                  strokeLinecap="round"        
                />
                <line
                  key="minus-black"
                  x1={x + lineLength + offset}
                  x2={x + lineLength * 2 + offset}
                  y1={y + lineLength * 1.5 + offset}
                  y2={y + lineLength * 1.5 + offset}
                  stroke="black"
                  strokeWidth={2 / window.devicePixelRatio}
                  vectorEffect="non-scaling-stroke"
                />
              </>
            ));
          } else {
            setCursorPath((
              <>
                <rect
                  key="all-white"
                  x={x + lineLength + offset}
                  y={y + lineLength + offset}
                  width={lineLength}
                  height={lineLength}
                  stroke="white"
                  fill="transparent" 
                  strokeWidth={4 / window.devicePixelRatio}
                  vectorEffect="non-scaling-stroke"
                  strokeLinecap="round"
                />
                <rect
                  key="all-black"
                  x={x + lineLength + offset}
                  y={y + lineLength + offset}
                  width={lineLength}
                  height={lineLength}
                  stroke="black"
                  fill="transparent" 
                  strokeWidth={2 / window.devicePixelRatio}
                  vectorEffect="non-scaling-stroke"
                />
              </>
            ));
          }
        }
      }

      dragPosition.current = { x: e.clientX, y: e.clientY };

      if (draggingLayer !== null) {
        if (layers[draggingLayer].type === "Canvas"){
          // Draw the floating layer
          const ctx = floatingLayer.current.getContext("2d");
          ctx.clearRect(0, 0, 64, Math.round(64 * aspectRatio));
          ctx.drawImage(
            canvases[draggingLayer].current, // Change from selectedLayer to draggingLayer
            0,
            0,
            64,
            Math.round(64 * aspectRatio)
          );
        }

        // Update the position of the floating layer
        
        // Start auto-scrolling{
        if(!scrollFrameRef.current) scrollFrameRef.current = requestAnimationFrame(handleAutoScroll);

        for (let i = canvases.length - 1; i >= 0; i--) {
          const layerRect = canvases[i].current.getBoundingClientRect();
          if (e.clientY < layerRect.top) {
            setMoveAbove(i + 1);
            return;
          }
        }
        setMoveAbove(0);
      } else {
        if (clickLayer !== null && startPosition.current && distance(startPosition.current, { x: e.clientX, y: e.clientY }) > 10){
          setDraggingLayer(clickLayer);
          setClickLayer(null);
        } 
        if (scrollFrameRef.current) {
          cancelAnimationFrame(scrollFrameRef.current);
          scrollFrameRef.current = null;
        }
      }
    };

    const handlePointerUp = (e) => {
      if (moveAbove !== null) {
        let indices = [...selectedLayers];
        if(!indices.includes(draggingLayer)){
          indices = [draggingLayer];
        } 
        reorderLayers(indices, moveAbove);
      } 
      setDraggingLayer(null);
      setClickLayer(null);
      setMoveAbove(null);
      if(layersWrapperInnerRef.current) layersWrapperInnerRef.current.style.overflowY = 'auto';
      
      if (dragInitTimer.current) {
        clearTimeout(dragInitTimer.current);
      } 

      // Stop auto-scrolling
      if (scrollFrameRef.current) {
        cancelAnimationFrame(scrollFrameRef.current);
        scrollFrameRef.current = null;
      }
    };

    const stopContext = (e) => {
      e.preventDefault();
      e.stopPropagation();
      return false;
    };

    // Attach event listeners
    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", handlePointerUp);
    window.addEventListener("pointermove", handlePointerMove);
    window.addEventListener("pointerup", handlePointerUp);
    window.addEventListener("contextmenu", stopContext);
    return () => {
      // Detach event listeners
      window.removeEventListener("touchend", handlePointerUp);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("pointermove", handlePointerMove);
      window.removeEventListener("pointerup", handlePointerUp);
      window.removeEventListener("contextmenu", stopContext);

      // Clean up auto-scrolling
      if (scrollFrameRef.current) {
        cancelAnimationFrame(scrollFrameRef.current);
        scrollFrameRef.current = null;
      }
    };
  }, [layers, selectedTool, previousTool, selectedLayer, selectedLayers,  draggingLayer, clickLayer, moveAbove, canvases, aspectRatio, reorderLayers, altKey, shiftKey, altButton, shiftButton, metaButton]);

  useEffect(() => {
    const handlePointerDown = (e) => {
      if (e.target.tagName.toLowerCase() !== "input") setRenameLayer(null);
    }

    window.addEventListener("pointerdown", handlePointerDown);
    return () => {
      window.removeEventListener("pointerdown", handlePointerDown);
    }
  }, []);

  const handleLayerVisibility = (index) => {
    const templayers = [...layers];
    templayers[index].visible = !templayers[index].visible;
    setLayers(templayers);
  };

  const handleLayerNameChange = (e, index) => {
    e.preventDefault();
    const templayers = [...layers];
    templayers[index].name = e.target.value.substring(0, 32);
    setLayers(templayers);
  };

  const handleRenameLayer = (index) => {
    setRenameLayer(index);
    setTimeout(() => {
      selectedInput.current.focus();
    }, 50);
  };

  useEffect(() => {
    // Check if layers actually have elements
    if (
      layers.length &&
      canvases.length &&
      layers.length === canvases.length
    ) {
      // Get context of selected local layer and offscreen canvas
      for (const index in canvases) {
        if(layers[index].type !== "Canvas") continue;
        const ctx = canvases[index].current.getContext("2d");
        ctx.globalCompositeOperation = "source-over";
        ctx.imageSmoothingEnabled = false;

        ctx.clearRect(0, 0, 64, Math.round(64 * aspectRatio));

        // Draw image from off-screen canvas onto local canvas
        ctx.drawImage(
          layers[index].ref.current,
          (layers[index].x - offset.x) * -1,
          (layers[index].y - offset.y) * -1,
          resolution.width,
          resolution.height,
          0,
          0,
          64,
          Math.round(64 * aspectRatio)
        );
      }
    }
  }, [layers, canvases, resolution, offset, aspectRatio, version]);

  useEffect(() => {
    setCanvases(
      layers.map((_, index) => {
        return canvases[index] || React.createRef();
      })
    );
  }, [layers]);

  const layerClass = (index) => {
    let classList = "layer-wrapper";
    if (selectedLayers.includes(index)) classList += " includes";
    if (selectedLayer === index) classList += " selected";
    if (moveAbove === index + 1) classList += " move-above";
    if (index === 0 && moveAbove === 0) classList += " move-below";
    return classList;
  };

  const visibilityIcon = (index) => {
    return layers[index].visible ? "icon-eye" : "icon-eye-blocked";
  };

  const magnetIcon = (index) => {
    return magnetLayer === index ? "icon-magnet" : "icon-magnet grayed";
  };

  const handleMagnet = (index) => {
    if(magnetLayer === index){
      setMagnetLayer(null);
    } else {
      setMagnetLayer(index);
    }
  };

  const mergeStyle = {
    color: (() => {
      // Check if there's any canvas layer lower than the selected layer
      const hasLowerCanvasLayer = layers.some((layer, index) => index < selectedLayer && layer.type === "Canvas");
  
      // Check if there are more than one canvas layers in selectedLayers
      const canvasLayerCount = selectedLayers.filter(index => layers[index].type === "Canvas").length;
  
      return hasLowerCanvasLayer || canvasLayerCount > 1 ? "#fff" : "#808080";
    })()
  };

  const deleteClass = () => {
    if(layers[selectedLayer].type === "Canvas"){
      const canvasCount = layers.filter(layer => layer.type === 'Canvas').length;
      if(canvasCount > 1){
        return "delete-layer";
      } else{
        return "delete-layer grayed";
      }
    } else {
      return "delete-layer";
    }
  };

  const guideSrc = (index) => {
    switch(layers[index].type){
      case "Line":
        return lineIcon;
      case "OnePoint":
        return perspectiveIcon;
      case "TwoPoint":
        return twoPointPerspectiveIcon;
      case "ThreePoint":
        return twoPointPerspectiveIcon;
      case "Grid":
        return gridIcon;
      case "Isometric":
        return isometricIcon
      case "Ellipse":
        return ellipseIcon;
    }
  }

  return (
    <div className="layers-wrapper-outer" ref={layersWrapperOuterRef}>
      <div className="layers-wrapper-inner" ref={layersWrapperInnerRef}>
        {layers.slice().reverse().map((layer, index) => {
          switch(layer.type){
            case "Canvas":
              return (<div
                  key={layers.length - 1 - index}
                  className={layerClass(layers.length - 1 - index)}
                  onPointerDown={(e) => {handleLayerPointerDown(e, layers.length - 1 - index);}}
                  onClick={(e) => {handleLayerClick(e, layers.length - 1 - index);}}
                >
                  <canvas
                    ref={canvases[layers.length - 1 - index]}
                    width={64}
                    height={Math.round(64 * aspectRatio)}
                    className="preview"
                    onPointerMove={handleCanvasMove}
                    onPointerOut={handleCanvasOut}
                  ></canvas>

                  {renameLayer === layers.length - 1 - index ?
                    <input
                      className="layer-name"
                      ref={selectedInput}
                      value={layers[layers.length - 1 - index].name}
                      onChange={(event) => {
                        handleLayerNameChange(
                          event,
                          layers.length - 1 - index
                        );
                      }}
                    ></input>
                    :
                    <div className="layer-name">{layers[layers.length - 1 - index].name}</div>
                  }

                  <button className="layer-button" onClick={() => { handleRenameLayer(layers.length - 1 - index); }}>
                    <span className="icon-pencil"></span>
                  </button>

                  <button
                    className="layer-button"
                    onClick={() => {
                      handleLayerVisibility(layers.length - 1 - index);
                    }}
                  >
                    <span
                      className={visibilityIcon(layers.length - 1 - index)}
                    ></span>
                  </button>
                </div>
              )
              break;
            default:
              return (
                <div
                  ref={canvases[layers.length - 1 - index]}
                  key={layers.length - 1 - index}
                  className={layerClass(layers.length - 1 - index)}
                  onPointerDown={(e) => {handleLayerPointerDown(e, layers.length - 1 - index);}}
                  onClick={(e) => {handleLayerClick(e, layers.length - 1 - index);}}
                >
                  <img
                    src={guideSrc(layers.length - 1 - index)}
                    width="64"
                    height="64"
                    className="guide-icon"
                  ></img>

                  {renameLayer === layers.length - 1 - index ?
                    <input
                      className="layer-name"
                      ref={selectedInput}
                      value={layers[layers.length - 1 - index].name}
                      onChange={(event) => {
                        handleLayerNameChange(
                          event,
                          layers.length - 1 - index
                        );
                      }}
                    ></input>
                    :
                    <div className="layer-name">{layers[layers.length - 1 - index].name}</div>
                  }

                  <button className="layer-button" onClick={() => { handleRenameLayer(layers.length - 1 - index); }}>
                    <span className="icon-pencil"></span>
                  </button>

                  <button
                    className="layer-button"
                    onClick={() => {
                      handleMagnet(layers.length - 1 - index);
                    }}
                  >
                    <span
                      className={magnetIcon(layers.length - 1 - index)}
                    ></span>
                  </button>

                  <button
                    className="layer-button"
                    onClick={() => {
                      handleLayerVisibility(layers.length - 1 - index);
                    }}
                  >
                    <span
                      className={visibilityIcon(layers.length - 1 - index)}
                    ></span>
                  </button>
                </div>
              )
              break;
          }
        })}
        {draggingLayer !== null && layers[draggingLayer].type === "Canvas" && (
          <canvas
            ref={floatingLayer}
            width={48}
            height={Math.round(48 * aspectRatio)}
            className="floating-layer"
            style={{
              left: `${dragPosition.current.x - 24}px`,
              top: `${dragPosition.current.y - 24 * aspectRatio}px`,
            }}
          ></canvas>
        )}
        {draggingLayer !== null  && layers[draggingLayer].type !== "Canvas" && (
          <img
            ref={floatingLayer}
            src={guideSrc(draggingLayer)}
            width="48"
            height="48"
            className="floating-layer"
            style={{
              left: `${dragPosition.current.x - 24}px`,
              top: `${dragPosition.current.y - 24}px`,
            }}
          ></img>
        )}
      </div>
      <svg className="layers-svg" width={svgWidth} height={svgHeight}>
        <g>{cursorPath}</g>
      </svg>
      <div className="layers-bottom">
          <button
            className="merge-layer"
            onClick={() => {
              mergeDown();
            }}
            style={mergeStyle}
          >
            <span className="icon-stack2"></span>
          </button>
        <button
          className={deleteClass()}
          onClick={() => {
            deleteLayer();
          }}
        >
          <span className="icon-bin"></span>
        </button>

        {layers.filter(layer => layer.type === 'Canvas').length < maxLayerCount ?
          <button
            className="add-layer"
            onClick={() => {
              addLayer('Canvas');
            }}
          >
            <span className="icon-plus"></span>
          </button>
            :
          <button className="add-layer gray">
            <span className="icon-plus"></span>
          </button>
        }
      </div>
    </div>
  );
}

export default Layers;
