import React, { useState, useEffect, useRef, useCallback } from "react";
import "../css/Panel.css";
import { useMainContext } from "../contexts/MainContext";
import TopRightWindow from "./TopRightWindow";
import BottomRightWindow from "./BottomRightWindow";

function RightPanel() {
  const rightWrapperRef = useRef(null);
  const dividerRef = useRef(null);
  
  const [dragging, setDragging] = useState(false);
  const [initialMousePosition, setInitialMousePosition] = useState(null);
  const [initialPanelWidth, setInitialPanelWidth] = useState(null);

  const {
    orientation,
    wrapperWidth,
    setWrapperWidth, 
    wrapperWidthPortrait,
    setWrapperWidthPortrait,
    loading,
    setLoading,
  } = useMainContext();

  const startDragging = (e) => {
    setInitialMousePosition(e.clientX);
    const rect = rightWrapperRef.current.getBoundingClientRect();
    setInitialPanelWidth(rect.width); // Get the initial width in pixels for calculation
    setDragging(true);
  };

  const stopDragging = useCallback((e) => {
    setDragging(false);
  });

  const handleDrag = useCallback(
    (e) => {
      if (dragging) {
        const newWidthPixels =
          initialPanelWidth - (e.clientX - initialMousePosition);
        const parentWidth = rightWrapperRef.current.parentElement.getBoundingClientRect().width;
        let newWidthPercentage = (newWidthPixels / parentWidth) * 100;
        if(newWidthPixels <= dividerRef.current.getBoundingClientRect().width) newWidthPercentage = 0;
        if(orientation === "landscape"){
          setWrapperWidth(`${newWidthPercentage}%`); // Update the width in percentage
        } else {
          setWrapperWidthPortrait(`${newWidthPercentage}%`); // Update the width in percentage
        }
      }
    },
    [dragging, initialMousePosition, initialPanelWidth, orientation]
  );

  const maximizeWindow = useCallback(() => {
    if (Math.abs(initialPanelWidth - rightWrapperRef.current.getBoundingClientRect().width) > 6) {
      return;
    }

    if(orientation === "landscape"){
      const sizeInt = parseInt(wrapperWidth.match(/(\d+(\.\d+)?)%/));
      if(sizeInt > 50){
        if(sizeInt === 100){
          setWrapperWidth('0%');
        } else {
          setWrapperWidth('100%');
        }
      } else {
        if(sizeInt === 0){
          setWrapperWidth('100%');
        } else {
          setWrapperWidth('0%');
        }
      }
    } else {
      const sizeInt = parseInt(wrapperWidthPortrait.match(/(\d+(\.\d+)?)%/));
      if(sizeInt > 50){
        if(sizeInt === 100){
          setWrapperWidthPortrait('0%');
        } else {
          setWrapperWidthPortrait('100%');
        }
      } else {
        if(sizeInt === 0){
          setWrapperWidthPortrait('100%');
        } else {
          setWrapperWidthPortrait('0%');
        }
      }
    }
  }, [orientation, initialPanelWidth, wrapperWidth, wrapperWidthPortrait]);

  useEffect(() => {
    dividerRef.current.addEventListener("pointerdown", startDragging);
    window.addEventListener("pointermove", handleDrag);
    window.addEventListener("pointerup", stopDragging);

    return () => {
      if (dividerRef.current) {
        dividerRef.current.removeEventListener("pointerdown", startDragging);
      }
      window.removeEventListener("pointermove", handleDrag);
      window.removeEventListener("pointerup", stopDragging);
    };
  }, [handleDrag, stopDragging]);

  // Set initial width based on parent element
  useEffect(() => {
    if (rightWrapperRef.current && rightWrapperRef.current.parentElement) {
      const parentWidth = rightWrapperRef.current.parentElement.getBoundingClientRect().width;
      if(orientation === "landscape" && !wrapperWidth){
        if(parentWidth < 400){
          setWrapperWidth("100%");
        } else if(parentWidth < 728){
          const width = parentWidth - 200;
          const initialWidthPercentage = (width / parentWidth) * 100;
          setWrapperWidth(`${initialWidthPercentage}%`);
        } else {
          const width = parentWidth - 300;
          const initialWidthPercentage = (width / parentWidth) * 100;
          setWrapperWidth(`${initialWidthPercentage}%`);
        }
      } else if(orientation === "portrait" && !wrapperWidthPortrait){
        if(parentWidth < 600){
          setWrapperWidthPortrait("100%");
        } else if(parentWidth < 728){
          const width = parentWidth - 200;
          const initialWidthPercentage = (width / parentWidth) * 100;
          setWrapperWidthPortrait(`${initialWidthPercentage}%`);
        } else {
          const width = parentWidth - 300;
          const initialWidthPercentage = (width / parentWidth) * 100;
          setWrapperWidthPortrait(`${initialWidthPercentage}%`);
        }
      }
    }
  }, [orientation, wrapperWidth, wrapperWidthPortrait]);

  const wrapperStyle = () => {
    if(orientation === "landscape"){
      return{ width: wrapperWidth, cursor: loading ? 'wait' : 'default' };
    } else {
      return{ width: wrapperWidthPortrait, cursor: loading ? 'wait' : 'default'  };
    }
  }

  return (
    <div
      ref={rightWrapperRef}
      className="right-wrapper"
      style={wrapperStyle()}
    >
      <div ref={dividerRef} className="right-divider">
        <div className="divider-decoration"></div>
      </div>
      <div className="right-panel">
        <TopRightWindow />
        <BottomRightWindow />
      </div>
    </div>
  );
}

export default RightPanel;
