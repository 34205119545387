import { useState, useRef, useEffect } from "react";
import { apiUrl } from "../constants";
import axios from 'axios';
import "../css/SignIn.css";

function SignIn({ onSuccessfulLogin }) {
    const [register, setRegister] = useState(false);
    const [signInEmail, setSignInEmail] = useState(null);
    const [signInPassword, setSignInPassword] = useState(null);
    const [registerUsername, setRegisterUsername] = useState(null);
    const [registerEmail, setRegisterEmail] = useState(null);
    const [registerPassword, setRegisterPassword] = useState(null);
    const [newsletter, setNewsletter] = useState(false);
    const [signInError, setSignInError] = useState(null);
    const [registerError, setRegisterError] = useState(null);

    const emailInputRef = useRef(null);

    useEffect(() => {
        // Automatically focus on the name input when the component is rendered
        if (emailInputRef.current) {
            emailInputRef.current.focus();
        }
    }, []);

    const handleSignInEmailChange = (e) => {
        setSignInEmail(e.target.value);
    };    
    const handleSignInPasswordChange = (e) => {
        setSignInPassword(e.target.value);
    };    
    const handleRegisterUsernameChange = (e) => {
        setRegisterUsername(e.target.value);
    };
    const handleRegisterEmailChange = (e) => {
        setRegisterEmail(e.target.value);
    };
    const handleRegisterPasswordChange = (e) => {
        setRegisterPassword(e.target.value);
    };
    const handleNewsletterChange = (checked) => {
        setNewsletter(checked);
    };

    const handleSignInDisplay = () => {
        setRegister(false);
        setSignInError(null);
        setSignInEmail(null);
        setSignInPassword(null);
    };

    const handleRegisterDisplay = () => {
        setRegister(true);
        setRegisterUsername(null);
        setRegisterError(null);
        setRegisterPassword(null);
        setRegisterEmail(null);
    };

    const signInSubmit = async (e) => {
        e.preventDefault();

        setSignInError(null);

        let error = [];

        if(!signInEmail){
            error.push(<p className="sign-in-error">Email is required.</p>);
        }

        if(!signInPassword){
            error.push(<p className="sign-in-error">Password is required.</p>);
        }

        if(error.length > 0) return setSignInError(error);
        
        const payload = {
            email: signInEmail.toLowerCase(),
            password: signInPassword
        };
        
        try {
            await axios.post(apiUrl + "/api/auth/login", payload)
                .then(response => {
                    if (response.status === 200) {
                        onSuccessfulLogin(response.data.userData);
                    }
                })
                .catch(error => {
                    // Check if the error response contains a custom message from the server
                    if (error.response && error.response.data && error.response.data.message) {
                        if(error.response.data.reset){
                            setSignInError(<p>{error.response.data.message} <button className="sign-in-reset-password" onClick={handleResetPassword}>Reset Password</button></p>);
                        } else if(error.response.data.verify){
                            console.log(error.response.data.verify)
                            setSignInError(<p>Email not verified. <button className="sign-in-reset-password" onClick={() => handleResend(error.response.data.verify)}>Resend email.</button></p>);
                        } else {
                            setSignInError(<p className="sign-in-error">{error.response.data.message}</p>);
                        }
                    } else {
                        console.log(error);
                        // Default error message if none provided from the server
                        setSignInError((<p className="sign-in-error">Failed to sign in.</p>));
                    }
                });
        } catch (error) {
            console.log(error);
            setSignInError((<p className="sign-in-error">Failed to sign in.</p>));
        }
    };

    const registerSubmit = async (e) => {
        e.preventDefault();

        setRegisterError(null);

        let error = [];

        if(!registerEmail){
            error.push(<p className="sign-in-error">Email is required.</p>);
        } else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(registerEmail)) {
            error.push(<p className="sign-in-error">Invalid email address.</p>);
        }

        if(!registerUsername){
            error.push(<p className="sign-in-error">Username is required.</p>);
        } else if(registerUsername.length < 4) {
            error.push(<p className="sign-in-error">Username must be 4 characters or more.</p>);
        } else if(registerUsername.length > 32) {
            error.push(<p className="sign-in-error">Password must be 32 characters or less.</p>);
        } else if(!/^[A-Za-z0-9_-]+$/.test(registerUsername)) {
            error.push(<p className="sign-in-error">Username contains invalid characters.</p>);
        }

        if(!registerPassword){
            error.push(<p className="sign-in-error">Password is required.</p>);
        } else if(registerPassword.length < 8) {
            error.push(<p className="sign-in-error">Password must be 8 characters or more.</p>);
        } else if(registerPassword.length > 32) {
            error.push(<p className="sign-in-error">Password must be 32 characters or less.</p>);
        } else{
            if(!/.*\d+.*/.test(registerPassword)){
                error.push(<p className="sign-in-error">Password must contain a number.</p>);
            }
            if(!/.*[a-z].*/.test(registerPassword)){
                error.push(<p className="sign-in-error">Password must contain a lower case letter.</p>);
            }
            if(!/.*[A-Z].*/.test(registerPassword)){
                error.push(<p className="sign-in-error">Password must contain a capital letter.</p>);
            }
            if(!/.*[@$!%*#?&^+].*/.test(registerPassword)){
                error.push(<p className="sign-in-error">Password must contain a special character.</p>);
            }
        }

        if(error.length > 0) return setRegisterError(error);
        
        const payload = {
            displayName: registerUsername,
            email: registerEmail,
            password: registerPassword,
            newsletter
        };
        
        try {
            await axios.post(apiUrl + "/api/auth/register", payload)
                .then(response => {
                    if (response.status === 200) {
                        setSignInEmail(registerEmail);
                        setSignInPassword(null);
                        setRegister(false);
                        setRegisterError(null);
                        setSignInError((<><p className="sign-in-success">User registered succesfuly.</p><p>Check your email for the verification link.</p></>));
                    }
                })
                .catch(error => {
                    // Check if the error response contains a custom message from the server
                    if (error.response && error.response.data && error.response.data.message) {
                        setRegisterError((<p className="sign-in-error">{error.response.data.message}</p>));
                    } else {
                        // Default error message if none provided from the server
                        setRegisterError((<p className="sign-in-error">Failed to register.</p>));
                    }
                });
        } catch (error) {
            console.error("An error occurred while registering:", error);
            setRegisterError((<p className="sign-in-error">Failed to register.</p>));
        }
    };

    const handleResetPassword = () => {
        const payload = {
            email: signInEmail
        }
        axios.post(apiUrl + "/api/auth/resetPasswordEmail", payload)
            .then(response => {
                if (response.status === 200) {
                    setSignInError(<p>A password reset email was sent.</p>);
                }
            })
            .catch(error => {
                // Check if the error response contains a custom message from the server
                if (error.response && error.response.data && error.response.data.message) {
                    setSignInError(<p className="sign-in-error">{error.response.data.message}</p>);
                } 
            });
    };

    const handleResend = (username) => {
        const payload = {
            username
        }
        axios.post(apiUrl + "/api/auth/resendEmail", payload)
            .then(response => {
                if (response.status === 200) {
                    setSignInError(<p>A verification email was resent</p>);
                }
            })
            .catch(error => {
                // Check if the error response contains a custom message from the server
                if (error.response && error.response.data && error.response.data.message) {
                    setSignInError(<p className="sign-in-error">{error.response.data.message}</p>);
                } 
            });
    };

    return (
        <div className="sign-in-wrapper">
        {!register && ( <>
            <h2 className="sign-in-heading">Sign In</h2>
            {signInError && <div>{signInError}</div>}
            <form className="sign-in-form" onSubmit={signInSubmit}>
                <label className="sign-in-label" htmlFor="email">Email:</label>
                <input ref={emailInputRef} className="sign-in-input" id="email"  value={signInEmail} onChange={handleSignInEmailChange} autoComplete="off"></input>
                <label className="sign-in-label" htmlFor="password">Password:</label>
                <input className="sign-in-input" id="password" type="password" value={signInPassword} onChange={handleSignInPasswordChange} autoComplete="off"></input>
                <div className="sign-in-right"><input type="submit" value="Sign In" className="sign-in-submit" /></div>
            </form>
            <div className="sign-in-bottom">Don't have an account? <button className="register-button" onClick={handleRegisterDisplay}>Register here.</button></div>
        </>)}
        {register && ( <>
            <h2 className="sign-in-heading">Register</h2>
            <div className="sign-in-consent">
                By registering you are agreeing to the <a href="/terms" target="_blank">Terms of Service</a> and <a href="/privacy" target="_blank">Privacy Policy</a>.
            </div>
            {registerError && <div>{registerError}</div>}
            <form className="sign-in-form" onSubmit={registerSubmit}>
                <label className="sign-in-label" htmlFor="email">Email:</label>
                <input className="sign-in-input" id="email" value={registerEmail} onChange={handleRegisterEmailChange} autoComplete="off"></input>
                <label className="sign-in-label" htmlFor="username">Username:</label>
                <input className="sign-in-input" id="username" value={registerUsername} onChange={handleRegisterUsernameChange} autoComplete="off"></input>
                <label className="sign-in-label" htmlFor="password">Password:</label>
                <input className="sign-in-input" id="password" value={registerPassword} type="password" onChange={handleRegisterPasswordChange} autoComplete="off"></input>
                <label className="sign-in-label" htmlFor="newsletter">Subscribe to Newsletter:
                    <input
                        className="tool-checkbox"
                        type="checkbox"
                        onChange={(e) => handleNewsletterChange(e.target.checked)}
                        checked={newsletter}
                        id="newsletter"
                    ></input>
                </label>
                <div className="sign-in-right"><input type="submit" value="Register" className="sign-in-submit" /></div>
            </form>
            <div className="sign-in-bottom">Aready have an account? <button className="sign-in-button" onClick={handleSignInDisplay}>Sign in here.</button></div>
        </>)}
        </div>
    );
}

export default SignIn;
