import { useState, useEffect } from "react";
import "../css/ToolOptions.css";
import { useMainContext } from "../contexts/MainContext";
import { maxCursorSize, metaKey} from "../constants";
import { onlyIntInput} from "../utils/basics";

function CropTool() {
  const { applyCrop, setCrop } = useMainContext();

  return (
    <>
      <h4>Crop Tool</h4>
      <div>
        <button className="crop-button" onClick={() =>{
            setCrop(null);
        }}>Reset</button>
        <button className="crop-button" onClick={() =>{
          applyCrop();
          setCrop(null);
        }}>Apply</button>
      </div>
    </>
  );
}

export default CropTool;
