import { useState } from "react";
import { apiUrl } from "../constants";
import axios from 'axios';
import "../css/Profile.css";
import { useUserContext } from "../contexts/UserContext";

function Profile() {
    const { user, setUser } = useUserContext();

    const [enterPassword, setEnterPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState(user.email);
    const [username, setUsername] = useState(user.displayName);
    const [newsletter, setNewsletter] = useState(user.newsletter);
    const [errorMessage, setErrorMessage] = useState(null);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };    
    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };    
    const handleNewsletterChange = (checked) => {
        setNewsletter(checked);
    };
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleApply = (e) => {
        e.preventDefault();
        setEnterPassword(true);
        setErrorMessage(null);
        setPassword('');
    };

    const handleBack = (e) => {
        e.preventDefault();
        setPassword('');
        setEnterPassword(false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        setErrorMessage(null);

        let error = [];

        if(!email) error.push(<p className="profile-error">Email is required.</p>);
        if(!username) error.push(<p className="profile-error">Username is required.</p>);
        if(!password) error.push(<p className="profile-error">Password is required.</p>);

        if(error.length > 0){
            setEnterPassword(false);
            setPassword('');
            return setErrorMessage(error);
        }

        if(email === user.email && username === user.displayName && newsletter === user.newsletter){
            setEnterPassword(false);
            setPassword('');
            return setErrorMessage(<p className="profile-error">No changes to save.</p>);
        }

        console.log(user.email);

        const payload = {
            oldEmail: user.email,
            email: email.toLowerCase(),
            displayName: username,
            newsletter,
            password
        };
        
        try {
            await axios.post(apiUrl + "/api/auth/updateProfile", payload)
                .then(response => {
                    if (response.status === 200) {
                        const userData = response.data.userData;
                        if(userData){
                            setUser(userData);

                            setEmail(userData.email);
                            setUsername(userData.displayName);
                            setNewsletter(userData.newsletter);

                            setPassword('');
                            setErrorMessage(<><p className="sign-in-success">User registered succesfuly.</p><p>Check your email for the verification link.</p></>);
                            setEnterPassword(false);
                        } else {
                            setPassword('');
                            setErrorMessage(<p className="profile-error">Error retreiving user data.</p>);
                            setEnterPassword(false);
                        }
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data && error.response.data.message) {
                        setPassword('');
                        setErrorMessage(<p className="profile-error">{error.response.data.message}</p>);
                        setEnterPassword(false);
                    } else {
                        console.log(error);
                        setPassword('');
                        setErrorMessage(<p className="profile-error">Failed to update profile.</p>);
                        setEnterPassword(false);
                    }
                });
        } catch (error) {
            console.log(error);
            setPassword('');
            setErrorMessage(<p className="profile-error">Failed to update profile.</p>);
            setEnterPassword(false);
        }
    };

    const handleResetPassword = (e) => {
        e.preventDefault();

        setErrorMessage(null);
        
        const payload = {
            email: user.email
        }
        axios.post(apiUrl + "/api/auth/resetPasswordEmail", payload)
            .then(response => {
                if (response.status === 200) {
                    setErrorMessage(<p>A password reset email was sent.</p>);
                }
            })
            .catch(error => {
                // Check if the error response contains a custom message from the server
                if (error.response && error.response.data && error.response.data.message) {
                    setErrorMessage(<p className="profile-error">{error.response.data.message}</p>);
                } else {
                    setErrorMessage(<p>An error occured.</p>);
                }
            });
    };

    return (
        <div className="profile-wrapper">
        {!user ?
            <div>You are not signed in.</div>
            :
            <>{!enterPassword ?
                <>
                    <h2 className="profile-heading">Your Profile</h2>
                    {errorMessage && <div>{errorMessage}</div>}
                    <form className="profile-form">
                        <label className="profile-label" htmlFor="email">Email:</label>
                        <input className="profile-input" id="email"  value={email} onChange={handleEmailChange}></input>
                        <label className="profile-label" htmlFor="username">Username:</label>
                        <input className="profile-input" id="username" value={username} onChange={handleUsernameChange}></input>
                        <label className="sign-in-label" htmlFor="newsletter">Subscribed to Newsletter:
                            <input
                                className="tool-checkbox"
                                type="checkbox"
                                onChange={(e) => handleNewsletterChange(e.target.checked)}
                                checked={newsletter}
                                id="newsletter"
                            ></input>
                        </label>
                        <div className="profile-right">
                            <input type="button" value="Reset Password" className="profile-submit" onClick={handleResetPassword} />
                            <input type="submit" value="Apply Changes" className="profile-submit" onClick={handleApply} />
                        </div>
                    </form>
                </>
                :
                <>
                    <h2 className="profile-heading">Submit Changes</h2>
                    {errorMessage && <div>{errorMessage}</div>}
                    <form className="profile-form" onSubmit={handleSubmit}>
                        <label className="profile-label" htmlFor="password">Password:</label>
                        <input className="profile-input" id="password" type="password" value={password} onChange={handlePasswordChange}></input>
                        <div className="profile-right">
                            <input type="button" value="Back" className="profile-submit" onClick={handleBack} />
                            <input type="submit" value="Submit" className="profile-submit" />
                        </div>
                    </form>
                </>
            }</>
        }
        </div>
    );
}

export default Profile;
