import { useEffect, useState } from "react";
import { apiUrl } from "../constants";
import { useParams } from "react-router-dom";
import axios from 'axios';
import "../css/Unsubscribe.css";

function Unsubscribe() {
    let { email } = useParams();
    let { token } = useParams();
    const [errorMessage, setErrorMessage] = useState(null);
    const [isValidToken, setIsValidToken] = useState(false);

    const verifyToken = (e) => {
        e.preventDefault();

        const payload = {
            email,
            token: token
        }
        axios.post(apiUrl + "/api/auth/unsubscribe", payload)
            .then(response => {
                if (response.status === 200) {
                    setIsValidToken(true);
                }
            })
            .catch(error => {
                if (error.response && error.response.data && error.response.data.message){
                    setErrorMessage(error.response.data.message);
                } else {
                    setErrorMessage("An error occured");
                }
            });
    };

    return (
        <div className="unsbscribe-center">
            {isValidToken ? 
                <div>
                    You have been unsubscribed! <a href="/">Home</a>
                </div>
                :
                <>{errorMessage ?
                    <p>{errorMessage}</p>
                    :
                    <>
                        <div>Unsubscribe from newsletter Emails</div>
                        <input type="submit" value="Unsubscribe" onClick={(event) => verifyToken(event)}></input>
                    </>
                }</>
            }
        </div>
    );
}

export default Unsubscribe;
