import chroma from "chroma-js";
import { maxCursorSize } from "../constants";
import PerspT from "perspective-transform";

export const pythag = (a, b) => {
  return Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2));
};

export const distance = (a, b) => {
  const delta = {
    x: a.x - b.x || 0,
    y: a.y - b.y || 0
  };
  return pythag(delta.x, delta.y);
};

export const rotateAroundCenter = (point, center, angle) => {
  const angleRad = -angle * (Math.PI / 180);
  const cosAngle = Math.cos(angleRad);
  const sinAngle = Math.sin(angleRad);
  const dx = point.x - center.x;
  const dy = point.y - center.y;
  return {
    x: center.x + dx * cosAngle - dy * sinAngle,
    y: center.y + dx * sinAngle + dy * cosAngle,
  };
};

export const scaleAroundCenter = (point, center, scaleRatio) => {
  // Calculate the distance from the point to the center
  const deltaX = point.x - center.x;
  const deltaY = point.y - center.y;

  // Scale the distance by the given ratio
  const scaledX = deltaX * scaleRatio;
  const scaledY = deltaY * scaleRatio;

  // Calculate the new point's coordinates by adding the scaled distance to the center
  const newPoint = {
      x: center.x + scaledX,
      y: center.y + scaledY
  };

  return newPoint;
};

export const scaleAroundCenter2 = (point, center, scaleRatioX, scaleRatioY, angle) => {
  // Convert angle to radians for calculation
  const radians = (angle * Math.PI) / 180;

  // Step 1: Translate point to origin (relative to center)
  const translatedX = point.x - center.x;
  const translatedY = point.y - center.y;

  // Step 2: Rotate the point to align with the axes
  const rotatedX = translatedX * Math.cos(radians) - translatedY * Math.sin(radians);
  const rotatedY = translatedX * Math.sin(radians) + translatedY * Math.cos(radians);

  // Step 3: Apply the scaling
  const scaledX = rotatedX * scaleRatioX;
  const scaledY = rotatedY * scaleRatioY;

  // Step 4: Rotate the point back to its original orientation
  const finalX = scaledX * Math.cos(-radians) - scaledY * Math.sin(-radians);
  const finalY = scaledX * Math.sin(-radians) + scaledY * Math.cos(-radians);

  // Step 5: Translate the point back to its original position relative to the center
  return {
      x: finalX + center.x,
      y: finalY + center.y
  };
};

export const getScaleRatio = (point, delta, center) => {
  // Calculate the original delta from the point to the center
  const originalDeltaX = point.x - center.x;
  const originalDeltaY = point.y - center.y;

  // Calculate the new delta after applying the movement
  const newDeltaX = originalDeltaX + delta.x;
  const newDeltaY = originalDeltaY + delta.y;

  // Calculate the original and new distances using signed distances
  const originalDistance = Math.sqrt(originalDeltaX * originalDeltaX + originalDeltaY * originalDeltaY);
  const newDistance = Math.sqrt(newDeltaX * newDeltaX + newDeltaY * newDeltaY);

  // Prevent division by zero or undefined behavior
  if (originalDistance === 0) {
    return 1; // Default scale ratio, can be adjusted as needed
  }

  // Determine the scale ratio
  let scaleRatio = newDistance / originalDistance;

  // Adjust for direction: if moving towards the center (sign flip), the scale ratio should be negative
  const dotProduct = originalDeltaX * newDeltaX + originalDeltaY * newDeltaY;
  if (dotProduct < 0) {
      scaleRatio = -scaleRatio;
  }

  return scaleRatio;
};

export const getScaleRatio2 = (point, delta, center, angle) => {
  // Convert angle to radians for calculation
  const radians = (angle * Math.PI) / 180;

  // Step 1: Translate point to origin (relative to center)
  const translatedX = point.x - center.x;
  const translatedY = point.y - center.y;

  // Step 2: Rotate the point to align with the axes
  const originalX = translatedX * Math.cos(radians) - translatedY * Math.sin(radians);
  const originalY = translatedX * Math.sin(radians) + translatedY * Math.cos(radians);

  // Step 3: Apply the cursor delta to the original point
  const deltaX = delta.x * Math.cos(radians) - delta.y * Math.sin(radians);
  const deltaY = delta.x * Math.sin(radians) + delta.y * Math.cos(radians);

  const newX = originalX + deltaX;
  const newY = originalY + deltaY;

  // Prevent division by zero or undefined behavior
  const scaleRatioX = originalX !== 0 ? newX / originalX : 1; // Default to 1 if originalX is 0
  const scaleRatioY = originalY !== 0 ? newY / originalY : 1; // Default to 1 if originalY is 0

  return { scaleRatioX, scaleRatioY };
};

export const hexToRgb = (hex) => {
  const truncated = hex.length < 6;
  const longReg = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i;
  const shortReg = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const result = truncated ? shortReg.exec(hex) : longReg.exec(hex);
  if (truncated) {
    result[1] += result[1];
    result[2] += result[2];
    result[3] += result[3];
  }
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  };
};

export const strokeCircle = (ctx, diameter, point) => {
  if (diameter == 1) {
    ctx.fillRect(point.x, point.y, 1, 1);
  } else if (diameter == 2) {
    ctx.fillRect(point.x - 1, point.y - 1, 2, 2);
  } else {
    // Adjusted radius
    const r = (diameter - 1) / 2;
    let x = 0;
    let y = r;
    let d = 3 - 2 * r;

    // Function to plot all eight points given a single point (x, y)
    const plotEightPoints = (cx, cy, x, y) => {
      ctx.fillRect(Math.ceil(cx + x - 0.5), Math.ceil(cy + y - 0.5), 1, 1);
      ctx.fillRect(Math.floor(cx - x - 0.5), Math.ceil(cy + y - 0.5), 1, 1);
      ctx.fillRect(Math.ceil(cx + x - 0.5), Math.floor(cy - y - 0.5), 1, 1);
      ctx.fillRect(Math.floor(cx - x - 0.5), Math.floor(cy - y - 0.5), 1, 1);
      ctx.fillRect(Math.ceil(cx + y - 0.5), Math.ceil(cy + x - 0.5), 1, 1);
      ctx.fillRect(Math.floor(cx - y - 0.5), Math.ceil(cy + x - 0.5), 1, 1);
      ctx.fillRect(Math.ceil(cx + y - 0.5), Math.floor(cy - x - 0.5), 1, 1);
      ctx.fillRect(Math.floor(cx - y - 0.5), Math.floor(cy - x - 0.5), 1, 1);
    };

    // Plot initial point
    plotEightPoints(point.x, point.y, x, y);

    while (x <= y) {
      x++;
      if (d < 0) {
        d += 4 * x + 6;
      } else {
        d += 4 * (x - y) + 10;
        y--;
      }
      plotEightPoints(point.x, point.y, x, y);
    }
  }
};

export const summate = (n, n1) => {
  n1 = Math.round(n1) || 1;
  n = Math.round(n) || 1;
  return (n / 2) * (1 + n) - ((n1 - 1) / 2) * n1;
};

export const makeCircle = (diameter, color) => {
  if (typeof color === "string") color = hexToRgb(color);
  const d = diameter;
  const r = d / 2;
  const canvas = document.createElement("canvas");
  canvas.width = d;
  canvas.height = d;
  const ctx = canvas.getContext("2d", { willReadFrequently: true });
  const imgData = ctx.createImageData(d, d);
  for (let i = 0; i < imgData.data.length / 4; i++) {
    const point = { x: (i % d) + 0.5, y: Math.floor(i / d) + 0.5 };
    const center = { x: r, y: r };
    if (distance(point, center) < r - 0.25) {
      imgData.data[i * 4 + 0] = color.r;
      imgData.data[i * 4 + 1] = color.g;
      imgData.data[i * 4 + 2] = color.b;
      imgData.data[i * 4 + 3] = 255;
    }
  }
  ctx.putImageData(imgData, 0, 0);
  return canvas;
};

export const makeStencil = (maxDiam, palette) => {
  const mD = Math.floor(maxDiam);
  const w = summate(mD);
  const h = palette.length * mD;
  const stencil = document.createElement("canvas");
  const ctx = stencil.getContext("2d", { willReadFrequently: true });
  stencil.width = w;
  stencil.height = h;
  for (let i = 0; i < palette.length; i++) {
    const y = i * mD;
    let x = 0;
    for (let j = 0; j < mD; j++) {
      const d = j + 1;
      ctx.drawImage(makeCircle(d, palette[i]), x, y);
      x += d;
    }
  }
  return stencil;
};

export const randomHex = () => {
  const characters = '0123456789abcdef';
  let result = '#';
  
  for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}

const groupAndSortEdges = (edges) => {
  // Group edges into separate paths
  const paths = [];
  const remainingEdges = new Set(edges);

  while (remainingEdges.size > 0) {
    let currentPath = [];
    let currentEdge = remainingEdges.values().next().value;
    remainingEdges.delete(currentEdge);

    while (currentEdge) {
      currentPath.push(currentEdge);

      let nextEdge = null;

      // Try to find the next edge that connects to the end of the current edge
      nextEdge = [...remainingEdges].find(
        (e) =>
          (e.x1 === currentEdge.x2 && e.y1 === currentEdge.y2) ||
          (e.x2 === currentEdge.x2 && e.y2 === currentEdge.y2)
      );

      // If not found, try to find an edge that connects to the start of the current edge
      if (!nextEdge) {
        nextEdge = [...remainingEdges].find(
          (e) =>
            (e.x1 === currentEdge.x1 && e.y1 === currentEdge.y1) ||
            (e.x2 === currentEdge.x1 && e.y2 === currentEdge.y1)
        );
      }

      if (nextEdge) {
        // Reorder the coordinates if needed to maintain the flow
        if (nextEdge.x1 !== currentEdge.x2 || nextEdge.y1 !== currentEdge.y2) {
          // Swap x1 with x2 and y1 with y2
          [nextEdge.x1, nextEdge.x2] = [nextEdge.x2, nextEdge.x1];
          [nextEdge.y1, nextEdge.y2] = [nextEdge.y2, nextEdge.y1];
        }
        remainingEdges.delete(nextEdge);
      }

      currentEdge = nextEdge;
    }

    if (currentPath.length > 0) {
      paths.push(currentPath);
    }
  }

  // Now you have separate paths, each sorted to match the flow
  return paths;
};

export const findPathsInCanvas = (canvas) => {
  const ctx = canvas.getContext("2d", { willReadFrequently: true });
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const pixels = imageData.data;
  const lines = [];
  const width = canvas.width;
  const height = canvas.height;

  // Helper function to check if a pixel is opaque
  const isOpaque = (index) => pixels[index + 3] > 0;

  // Helper function to add line if there's a boundary
  const addLine = (x1, y1, x2, y2) => {
      lines.push({ x1, y1, x2, y2 });
  };

  for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
          const index = (y * width + x) * 4;

          // Check right neighbor
          if (x < width - 1) {
              const rightIndex = index + 4;
              if (isOpaque(index) !== isOpaque(rightIndex)) {
                  addLine(x + 1, y, x + 1, y + 1);
              }
          } else if (isOpaque(index)) { // Check right boundary
              addLine(width, y, width, y + 1);
          }

          // Check bottom neighbor
          if (y < height - 1) {
              const bottomIndex = index + (width * 4);
              if (isOpaque(index) !== isOpaque(bottomIndex)) {
                  addLine(x, y + 1, x + 1, y + 1);
              }
          } else if (isOpaque(index)) { // Check bottom boundary
              addLine(x, height, x + 1, height);
          }
      }
  }

  // Check top and left boundaries if the first row or column is opaque
  for (let x = 0; x < width; x++) {
      if (isOpaque(x * 4)) { // Check top boundary
          addLine(x, 0, x + 1, 0);
      }
  }
  for (let y = 0; y < height; y++) {
      if (isOpaque(y * width * 4)) { // Check left boundary
          addLine(0, y, 0, y + 1);
      }
  }
  
  return groupAndSortEdges(lines);
};

export const floodFill = (imageData, startX, startY, width, height, tolerance = 0) => {
  const startIndex = (startY * width + startX) * 4;
  
  // Extract the start color components
  const startR = imageData.data[startIndex];
  const startG = imageData.data[startIndex + 1];
  const startB = imageData.data[startIndex + 2];
  const startA = imageData.data[startIndex + 3];

  // Create a chroma color object for the start color
  const startColor = chroma([startR, startG, startB]);

  let queue = [[startX, startY]];
  let indexes = [];
  let processed = new Uint8Array(width * height); // Bitmap to track processed pixels

  while (queue.length > 0) {
    let [x, y] = queue.shift();
    let index = (y * width + x) * 4;

    if (x < 0 || y < 0 || x >= width || y >= height || processed[y * width + x]) {
      continue;
    }

    processed[y * width + x] = 1; // Mark as processed

    // Extract current pixel color components
    let currentR = imageData.data[index];
    let currentG = imageData.data[index + 1];
    let currentB = imageData.data[index + 2];
    let currentA = imageData.data[index + 3];

    // If start color is not fully opaque (alpha < 255), also check the alpha distance
    if (startA < 255) {
      
      // Continue only if both color and alpha are within tolerance
      if (currentA < 255) {
        indexes.push(index);
        // Push neighboring pixels onto the queue
        queue.push([x + 1, y]);
        queue.push([x - 1, y]);
        queue.push([x, y + 1]);
        queue.push([x, y - 1]);
      }
    } else {
      // If the start color is fully opaque, ignore the alpha channel and only check color
      if(!tolerance){//improve performance by not checking distance if tolerance is 0
        if (
          startR === currentR &&
          startG === currentG &&
          startB === currentB
        ) {
          indexes.push(index);
          // Push neighboring pixels onto the queue
          queue.push([x + 1, y]);
          queue.push([x - 1, y]);
          queue.push([x, y + 1]);
          queue.push([x, y - 1]);
        }
      } else {
        // Create a chroma color object for the current pixel color
        let currentColor = chroma([currentR, currentG, currentB]);
        
        // Check color distance without alpha channel
        let colorDistance = chroma.distance(currentColor, startColor, 'rgb');
        if (currentA > 0 && colorDistance <= tolerance) {
          indexes.push(index);
          // Push neighboring pixels onto the queue
          queue.push([x + 1, y]);
          queue.push([x - 1, y]);
          queue.push([x, y + 1]);
          queue.push([x, y - 1]);
        }
      }
    }
  }

  return indexes;
};


export const rectangleSides = (x1, y1, x2, y2, angle) => {
  // Calculate the differences in x and y coordinates
  const dx = x2 - x1;
  const dy = y2 - y1;

  const radians = (angle) * Math.PI / 180;

  // Calculate width and height using rotation matrix transformations
  const width = dx * Math.cos(radians) - dy * Math.sin(radians);
  const height = dx * Math.sin(radians) + dy * Math.cos(radians);
  return { width, height };
};

export const calculateSquareCorner = (x1, y1, x2, y2, angle) => {
  let radians;

  const sides = rectangleSides(x1,y1,x2,y2,angle);
  const side = Math.max(Math.abs(sides.width), Math.abs(sides.height));
  
  if(sides.width > 0){
    if(sides.height < 0){
      radians = (-angle - 45) * Math.PI / 180
    } else {
      radians = (-angle + 45) * Math.PI / 180
    }
  } else if(sides.height > 0){
    radians = (-angle + 135) * Math.PI / 180
  } else {
    radians = (-angle + 225) * Math.PI / 180
  }

  const diagonal = side * Math.sqrt(2);

  x2 = x1 + diagonal * Math.cos(radians);
  y2 = y1 + diagonal * Math.sin(radians);

  return { x2, y2 };
};


export const drawRectangle = (ctx, x1, y1, x2, y2, angle) => {
  const sides = rectangleSides(x1, y1, x2, y2, angle);
  const centerX = (x1 + x2) / 2;
  const centerY = (y1 + y2) / 2;

  ctx.save();
  ctx.translate(centerX, centerY);
  ctx.rotate(-angle * Math.PI / 180);
  ctx.fillRect(sides.width / -2, sides.height / -2, sides.width, sides.height);
  ctx.restore();
  return {width: sides.width, height: sides.height};
}

export const drawEllipse = (ctx, x1, y1, x2, y2, angle) => {
  const sides = rectangleSides(x1, y1, x2, y2, angle);
  const centerX = (x1 + x2) / 2;
  const centerY = (y1 + y2) / 2;
  const radiusX = Math.abs(sides.width / 2);
  const radiusY = Math.abs(sides.height / 2); 

  ctx.save();
  ctx.translate(centerX, centerY);
  ctx.rotate(-angle * Math.PI / 180);
  ctx.beginPath();
  ctx.ellipse(0, 0, radiusX, radiusY, 0, 0, 2 * Math.PI);
  ctx.fill(); 
  ctx.restore();
  return {width: sides.width, height: sides.height};
};

export const extendLineToBounds = (startPoint, endPoint) => {
  const extensionDistance = 90000; // Large distance to extend the line

  const dx = endPoint.x - startPoint.x;
  const dy = endPoint.y - startPoint.y;

  const lineLength = Math.hypot(dx, dy);

  // Normalize the direction vector
  const directionX = dx / lineLength;
  const directionY = dy / lineLength;

  // Extend the start and end points
  const extendedStartPoint = {
    x: startPoint.x - directionX * extensionDistance,
    y: startPoint.y - directionY * extensionDistance
  };

  const extendedEndPoint = {
    x: endPoint.x + directionX * extensionDistance,
    y: endPoint.y + directionY * extensionDistance
  };

  return { startPoint: extendedStartPoint, endPoint: extendedEndPoint };
};


export const closestAngle = (targetAngle, angles) => {
  // Function to calculate the smallest difference considering the 0/360 boundary
  const angleDifference = (a, b) => {
    let diff = Math.abs(a - b) % 360;
    return diff > 180 ? 360 - diff : diff;
  };

  // Find the angle with the smallest difference
  let closest = angles[0];
  let smallestDifference = angleDifference(closest, targetAngle);

  for (let i = 1; i < angles.length; i++) {
    let currentAngle = angles[i];
    let difference = angleDifference(currentAngle, targetAngle);

    if (difference < smallestDifference) {
      smallestDifference = difference;
      closest = currentAngle;
    }
  }

  return closest;
};

export const closestAngleCoord = (start, end, angles) => {
  const deltaX = end.x - start.x;
  const deltaY = end.y - start.y;
  const angleInRadians = Math.atan2(deltaY, deltaX);
  let targetAngle = angleInRadians * (180 / Math.PI);
  targetAngle = ((targetAngle % 360) + 360) % 360;

  // Function to calculate the smallest difference considering the 0/360 boundary
  const angleDifference = (a, b) => {
    let diff = Math.abs(a - b) % 360;
    return diff > 180 ? 360 - diff : diff;
  };

  // Find the angle with the smallest difference
  let closest = angles[0];
  let smallestDifference = angleDifference(closest, targetAngle);

  for (let i = 1; i < angles.length; i++) {
    let currentAngle = angles[i];
    let difference = angleDifference(currentAngle, targetAngle);

    if (difference < smallestDifference) {
      smallestDifference = difference;
      closest = currentAngle;
    }
  }

  return closest;
};

export const deriveVanishingPoints = (center, focalLength, tilt, angle) => {
  if(angle === 0 || angle === -90){
    angle += 0.01;
  } else if(angle === 90){
    angle -= 0.01;
  }
  const angleRad = angle * (Math.PI / 180);

  // Calculate distances to vanishing points
  const distance1 = focalLength * Math.tan(angleRad);
  const distance2 = focalLength * Math.tan(Math.PI / 2 - angleRad);

  // Calculate initial vanishing points on the horizon without tilt
  const vp1x = center.x + distance1;
  const vp2x = center.x - distance2;

  const vp1 = rotateAroundCenter({x: vp1x, y: center.y}, center, tilt);
  const vp2 = rotateAroundCenter({x: vp2x, y: center.y}, center, tilt);

  return { vp1, vp2 };
};

export const deriveAngleFromCursor = (center, focalLength, tilt, cursor) => {
  // Rotate the cursor position back to horizontal
  const cursorRotated = rotateAroundCenter(cursor, center, -tilt);

  // Calculate the distance from the center to the rotated cursor along the horizon
  const distanceFromCenter = cursorRotated.x - center.x;

  // Calculate the angle using trigonometry
  const angleRad = Math.atan2(distanceFromCenter, focalLength);
  const angleDeg = angleRad * (180 / Math.PI);

  return angleDeg;
};

export const deriveAngleFromCursor2 = (center, focalLength, tilt, cursor) => {
  // Rotate the cursor position back to horizontal
  const cursorRotated = rotateAroundCenter(cursor, center, -tilt);

  // Calculate the distance from the center to the rotated cursor along the horizon
  const distanceFromCenter = center.x - cursorRotated.x;

  // Calculate the angle using trigonometry
  const angleRad = Math.atan(focalLength / distanceFromCenter);
  const angleDeg = angleRad * (180 / Math.PI);

  return angleDeg;
};

export const calculateRotatedPoint = (x, y, angle, cx, cy) => {
  const radians = angle * (Math.PI / 180);
  const cos = Math.cos(radians);
  const sin = Math.sin(radians);
  const nx = cos * (x - cx) - sin * (y - cy) + cx;
  const ny = sin * (x - cx) + cos * (y - cy) + cy;
  return { x: nx, y: ny };
};

export const getEndPoint = (x, y, angle, length) => {
  const radians = angle * (Math.PI / 180);
  return {
    x: x + length * Math.cos(radians),
    y: y - length * Math.sin(radians),
  };
};

export const distanceToEllipseEdge = (ellipse, point) => {
  const { center, width, height, angle } = ellipse;
  const { x, y } = point;
  
  // Step 1: Translate the point and ellipse to origin
  const translatedX = x - center.x;
  const translatedY = y - center.y;

  // Step 2: Rotate the coordinate system to align the ellipse with axes
  const radAngle = -angle * (Math.PI / 180); // Convert angle to radians
  const cosAngle = Math.cos(radAngle);
  const sinAngle = Math.sin(radAngle);

  const rotatedX = translatedX * cosAngle + translatedY * sinAngle;
  const rotatedY = -translatedX * sinAngle + translatedY * cosAngle;

  // Step 3: Compute the distance along the radial line
  const a = width / 2;
  const b = height / 2;

  // Parametric form of the ellipse: x = a * cos(t), y = b * sin(t)
  // Find t such that the point (rotatedX, rotatedY) is on the same radial line
  const t = Math.atan2(rotatedY * a, rotatedX * b);

  // Compute the point on the ellipse
  const ellipseX = a * Math.cos(t);
  const ellipseY = b * Math.sin(t);

  // Compute the distance from (0, 0) to the edge point
  const distanceToEdge = Math.sqrt(ellipseX * ellipseX + ellipseY * ellipseY);

  // Compute the distance from (0, 0) to the point
  const distanceToPoint = Math.sqrt(rotatedX * rotatedX + rotatedY * rotatedY);

  // Step 4: Transform the distance back to the original coordinate system
  const distance = distanceToPoint - distanceToEdge;

  // If the point is inside the ellipse, the distance is negative
  return distance;
};

export const pointDistanceAwayFromEllipse = (ellipse, point, distanceFromEdge)  =>{
  const { center, width, height, angle } = ellipse;
  const { x, y } = point;

  // Step 1: Translate the point and ellipse to origin
  const translatedX = x - center.x;
  const translatedY = y - center.y;

  // Step 2: Rotate the coordinate system to align the ellipse with axes
  const radAngle = -angle * (Math.PI / 180); // Convert angle to radians
  const cosAngle = Math.cos(radAngle);
  const sinAngle = Math.sin(radAngle);

  const rotatedX = translatedX * cosAngle + translatedY * sinAngle;
  const rotatedY = -translatedX * sinAngle + translatedY * cosAngle;

  // Step 3: Compute the angle t for the given point
  const a = width / 2;
  const b = height / 2;

  // Parametric form of the ellipse: x = a * cos(t), y = b * sin(t)
  const t = Math.atan2(rotatedY * a, rotatedX * b);

  // Compute the point on the ellipse
  const ellipseX = a * Math.cos(t);
  const ellipseY = b * Math.sin(t);

  // Compute the distance from (0, 0) to the edge point
  const distanceToEdge = Math.sqrt(ellipseX * ellipseX + ellipseY * ellipseY);

  // Step 4: Compute the target distance from the origin
  const targetDistance = distanceToEdge + distanceFromEdge;

  // Step 5: Compute the coordinates of the target point
  const scale = targetDistance / distanceToEdge;
  const targetX = ellipseX * scale;
  const targetY = ellipseY * scale;

  // Step 6: Rotate the coordinates back to the original system
  const finalX = targetX * cosAngle - targetY * sinAngle + center.x;
  const finalY = targetX * sinAngle + targetY * cosAngle + center.y;

  return { x: finalX, y: finalY };
};

export const getRatioToEllipseEdge = (ellipse, point) => {
  const { center, width, height, angle } = ellipse;
  const { x, y } = point;

  // Step 1: Translate the point and ellipse to origin
  const translatedX = x - center.x;
  const translatedY = y - center.y;

  // Step 2: Rotate the coordinate system to align the ellipse with axes
  const radAngle = -angle * (Math.PI / 180); // Convert angle to radians
  const cosAngle = Math.cos(radAngle);
  const sinAngle = Math.sin(radAngle);

  const rotatedX = translatedX * cosAngle + translatedY * sinAngle;
  const rotatedY = -translatedX * sinAngle + translatedY * cosAngle;

  // Step 3: Compute the angle t for the given point
  const a = width / 2;
  const b = height / 2;

  // Parametric form of the ellipse: x = a * cos(t), y = b * sin(t)
  const t = Math.atan2(rotatedY * a, rotatedX * b);

  // Compute the point on the ellipse
  const ellipseX = a * Math.cos(t);
  const ellipseY = b * Math.sin(t);

  // Compute the distance from (0, 0) to the edge point
  const distanceToEdge = Math.sqrt(ellipseX * ellipseX + ellipseY * ellipseY);

  // Compute the distance from (0, 0) to the given point
  const distanceToPoint = Math.sqrt(rotatedX * rotatedX + rotatedY * rotatedY);

  // Step 4: Calculate the ratio
  const ratio = distanceToPoint / distanceToEdge;

  return ratio;
}

export const getPointAtRatioFromEllipseEdge = (ellipse, point, ratio, diameter) => {
  const { center, width, height, angle } = ellipse;
  const { x, y } = point;

  // Step 1: Translate the point and ellipse to origin
  const translatedX = x - center.x;
  const translatedY = y - center.y;

  // Step 2: Rotate the coordinate system to align the ellipse with axes
  const radAngle = -angle * (Math.PI / 180); // Convert angle to radians
  const cosAngle = Math.cos(radAngle);
  const sinAngle = Math.sin(radAngle);

  const rotatedX = translatedX * cosAngle + translatedY * sinAngle;
  const rotatedY = -translatedX * sinAngle + translatedY * cosAngle;

  // Step 3: Compute the angle t for the given point
  const a = width / 2;
  const b = height / 2;

  // Parametric form of the ellipse: x = a * cos(t), y = b * sin(t)
  const t = Math.atan2(rotatedY * a, rotatedX * b);

  // Compute the point on the ellipse
  const ellipseX = a * Math.cos(t);
  const ellipseY = b * Math.sin(t);

  // Compute the distance from (0, 0) to the edge point
  const distanceToEdge = Math.sqrt(ellipseX * ellipseX + ellipseY * ellipseY);

  // Step 4: Compute the target distance from the origin using the ratio
  const targetDistance = distanceToEdge * ratio;

  // Step 5: Compute the coordinates of the target point
  const scale = targetDistance / distanceToEdge;
  const targetX = ellipseX * scale;
  const targetY = ellipseY * scale;

  // Step 6: Rotate the coordinates back to the original system
  let finalX = targetX * cosAngle - targetY * sinAngle + center.x;
  let finalY = targetX * sinAngle + targetY * cosAngle + center.y;

  finalX = (x > center.x && diameter % 2 === 0) ? Math.round(finalX) : finalX;
  finalY = (y > center.y && diameter % 2 === 0) ? Math.round(finalY) : finalY;

  return { x: finalX, y: finalY };
}

export const rampPalette = (palette) => {
  const newPalette = [];
  const assigned = [];
  const hslPalette = [];
  
  for(const color of palette){
    hslPalette.push(chroma(color).hsl());
  }

  const blackDistances = [];
  for(let i = 0; i < palette.length; i++){
    blackDistances.push({
      index: i, 
      distance: chroma.distance(palette[i], chroma("black"))
    })
  }
  const blackSorted = blackDistances.sort((a, b) => a.distance - b.distance);
  assigned.push(blackSorted[0].index);
  newPalette.push(palette[blackSorted[0].index]);

  if(newPalette.length === palette.length) return newPalette;

  const whiteDistances = [];
  for(let i = 0; i < palette.length; i++){
    if(assigned.includes(i)) continue;
    whiteDistances.push({
      index: i, 
      distance: chroma.distance(palette[i], chroma("white"))
    })
  }
  const whiteSorted = whiteDistances.sort((a, b) => a.distance - b.distance);
  assigned.push(whiteSorted[0].index);
  newPalette.push(palette[whiteSorted[0].index]);

  if(newPalette.length === palette.length) return newPalette;

  const grays = [];
  for(let i = 0; i < palette.length; i++){
    if(assigned.includes(i)) continue;
    if(hslPalette[i][1] === NaN || hslPalette[i][1] < 0.1){
      grays.push({
        index: i,
        hsl: hslPalette[i]
      });
      assigned.push(i);
    }
  }
  const graysSorted = grays.sort((a, b) => b.hsl[2] - a.hsl[2]);
  for(const gray of graysSorted){
    newPalette.push(palette[gray.index]);
  }

  if(newPalette.length === palette.length) return newPalette;

  const skinTones = [];
  const skinToneRange = {
    hue: [20, 50],
    saturation: [0, 0.6],
    lightness: [0.4, 0.95]
  };

  for(let i = 0; i < palette.length; i++){
    if(assigned.includes(i)) continue;
    const [h, s, l] = hslPalette[i];
    if(
      h >= skinToneRange.hue[0] && h <= skinToneRange.hue[1] &&
      s >= skinToneRange.saturation[0] && s <= skinToneRange.saturation[1] &&
      l >= skinToneRange.lightness[0] && l <= skinToneRange.lightness[1]
    ){
      skinTones.push({
        index: i,
        hsl: hslPalette[i]
      });
      assigned.push(i);
    }
  }
  const skinTonesSorted = skinTones.sort((a, b) => b.hsl[2] - a.hsl[2]);
  for(const skinTone of skinTonesSorted){
    newPalette.push(palette[skinTone.index]);
  }

  if(newPalette.length === palette.length) return newPalette;

  const hueBrackets = Array.from({ length: 24 }, () => [[], []]);

  for(let i = 0; i < palette.length; i++){
    if(!assigned.includes(i)){
      const [h, s, l] = hslPalette[i];
      const bracketIndex = Math.floor(h / 15);
      const subBracketIndex = s > 0.65 ? 0 : 1;
      hueBrackets[bracketIndex][subBracketIndex].push({
        index: i,
        hsl: hslPalette[i]
      });
      assigned.push(i);
    }
  }

  for(const bracket of hueBrackets){
    for(const subBracket of bracket){
      const sortedSubBracket = subBracket.sort((a, b) => b.hsl[2] - a.hsl[2]);
      for(const color of sortedSubBracket){
        newPalette.push(palette[color.index]);
      }
    }
  }


  return newPalette;
};

export const pixelLine = (ctx, sx, sy, tx, ty, lineWidth = 1) => {
  // Create a triangle object
  let tri = {};
  function getTriangle(x1, y1, x2, y2, ang) {
    if (Math.abs(x1 - x2) > Math.abs(y1 - y2)) {
      tri.x = Math.sign(Math.cos(ang));
      tri.y = Math.tan(ang) * Math.sign(Math.cos(ang));
      tri.long = Math.abs(x1 - x2);
    } else {
      tri.x = Math.tan((Math.PI / 2) - ang) * Math.sign(Math.cos((Math.PI / 2) - ang));
      tri.y = Math.sign(Math.cos((Math.PI / 2) - ang));
      tri.long = Math.abs(y1 - y2);
    }
  }
  // Finds the angle of (x,y) on a plane from the origin
  function getAngle(x, y) {
    return Math.atan(y / (x === 0 ? 0.01 : x)) + (x < 0 ? Math.PI : 0);
  }

  // Finds the angle of (x,y) on a plane from the origin
  let angle = getAngle(tx - sx, ty - sy); // Angle of line

  getTriangle(sx, sy, tx, ty, angle);

  for (let i = 0; i < tri.long; i++) {
    let thispoint = { x: Math.round(sx + tri.x * i), y: Math.round(sy + tri.y * i) };
    // For each point along the line
    ctx.fillRect(
      thispoint.x - Math.floor(lineWidth / 2),
      thispoint.y - Math.floor(lineWidth / 2),
      lineWidth,
      lineWidth
    );
  }

  // Fill endpoint 1px/1px
  ctx.fillRect(
    Math.round(tx) - Math.floor(lineWidth / 2), // Round for perfect pixels
    Math.round(ty) - Math.floor(lineWidth / 2),
    lineWidth,
    lineWidth
  );
};

export const isPointInPolygon = (point, polygon, threshold = 0) => {
  let x = point.x, y = point.y;
  let inside = false;

  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      let xi = polygon[i][0], yi = polygon[i][1];
      let xj = polygon[j][0], yj = polygon[j][1];

      let intersect = ((yi > y) != (yj > y)) &&
                      (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect) inside = !inside;

      if(threshold > 0){
        const nearLine = isPointNearLine(point, {x: xi, y: yi}, {x: xj, y: yj}, threshold);
        if(nearLine) return true;
      }
  }

  return inside;
};

export const getMidpoint = (point1, point2) => {
  let midX = (point1.x + point2.x) / 2;
  let midY = (point1.y + point2.y) / 2;
  return {x: midX, y: midY};
};

export const isPointNearLine = (point1, point2, point3, threshold) => {
  // Calculate the numerator of the distance formula
  let numerator = Math.abs((point3.y - point2.y) * point1.x - (point3.x - point2.x) * point1.y + point3.x * point2.y - point3.y * point2.x);

  // Calculate the denominator of the distance formula
  let denominator = Math.sqrt(Math.pow(point3.y - point2.y, 2) + Math.pow(point3.x - point2.x, 2));

  // Calculate the perpendicular distance from the point to the line
  let distance = numerator / denominator;

  // Check if the perpendicular distance is within the threshold
  if (distance <= threshold) {
    // Check if the point's projection falls within the bounds of the segment
    let dotProduct = (point1.x - point2.x) * (point3.x - point2.x) + (point1.y - point2.y) * (point3.y - point2.y);
    let lengthSquared = Math.pow(point3.x - point2.x, 2) + Math.pow(point3.y - point2.y, 2);

    if (dotProduct >= 0 && dotProduct <= lengthSquared) {
      return true; // The point is near the segment within the threshold
    }
  }

  // Check if the point is within the threshold of the endpoints (corners)
  const distanceToPoint2 = Math.sqrt(Math.pow(point1.x - point2.x, 2) + Math.pow(point1.y - point2.y, 2));
  const distanceToPoint3 = Math.sqrt(Math.pow(point1.x - point3.x, 2) + Math.pow(point1.y - point3.y, 2));

  return distanceToPoint2 <= threshold || distanceToPoint3 <= threshold;
};

const isConcave = (c1, c2, c3, c4) => {
  // Helper function to calculate the cross product of two vectors
  const crossProduct = (v1, v2) => v1.x * v2.y - v1.y * v2.x;

  // Vectors formed by the edges of the quadrilateral
  const v1 = { x: c2.x - c1.x, y: c2.y - c1.y };
  const v2 = { x: c3.x - c2.x, y: c3.y - c2.y };
  const v3 = { x: c4.x - c3.x, y: c4.y - c3.y };
  const v4 = { x: c1.x - c4.x, y: c1.y - c4.y };

  // Cross products of adjacent edges
  const cp1 = crossProduct(v1, v2);
  const cp2 = crossProduct(v2, v3);
  const cp3 = crossProduct(v3, v4);
  const cp4 = crossProduct(v4, v1);

  // Check if the cross products have mixed signs, indicating a concave quadrilateral
  return (cp1 > 0 && (cp2 < 0 || cp3 < 0 || cp4 < 0)) ||
         (cp1 < 0 && (cp2 > 0 || cp3 > 0 || cp4 > 0));
};

export const drawTransformedImage = (ctx, imageData, width, height, transform) => {
  if (isConcave(transform.c1, transform.c2, transform.c3, transform.c4)) return;
  
  const srcCorners = [0, 0, width, 0, width, height, 0, height];
  const dstCorners = [transform.c1.x, transform.c1.y, transform.c2.x, transform.c2.y, transform.c3.x, transform.c3.y, transform.c4.x, transform.c4.y];
  const perspT = PerspT(srcCorners, dstCorners);

  const minX = Math.floor(Math.min(transform.c1.x, transform.c2.x, transform.c3.x, transform.c4.x));
  const minY = Math.floor(Math.min(transform.c1.y, transform.c2.y, transform.c3.y, transform.c4.y));
  const maxX = Math.ceil(Math.max(transform.c1.x, transform.c2.x, transform.c3.x, transform.c4.x));
  const maxY = Math.ceil(Math.max(transform.c1.y, transform.c2.y, transform.c3.y, transform.c4.y));

  // Iterate over each pixel in the destination canvas
  for (let y = minY; y < maxY; y ++) {
    for (let x = minX; x < maxX; x ++) {
      const sourcePoint = perspT.transformInverse(x + 0.5, y + 0.5);
      const srcX = Math.floor(sourcePoint[0]);
      const srcY = Math.floor(sourcePoint[1]);
      if (srcX >= 0 && srcX < width && srcY >= 0 && srcY < height) {
        const index = (srcY * width + srcX) * 4;

        const r = imageData[index];
        const g = imageData[index + 1];
        const b = imageData[index + 2];
        const a = imageData[index + 3];

        if(a > 0){
          // Draw the pixel on the destination canvas
          ctx.fillStyle = `rgba(${r},${g},${b}, 255)`;
          ctx.fillRect(Math.floor(x), Math.floor(y), 1, 1);
        }
      }
    }
  }
};

export const getCentroid = (quadrilateral) => {
  const centroidX = (quadrilateral.c1.x + quadrilateral.c2.x + quadrilateral.c3.x + quadrilateral.c4.x) / 4;
  const centroidY = (quadrilateral.c1.y + quadrilateral.c2.y + quadrilateral.c3.y + quadrilateral.c4.y) / 4;

  return { x: centroidX, y: centroidY };
}
